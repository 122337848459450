import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { imageShape } from '../shapes/ImageShapes';

const ImageCheckList = ({ images, onCheckboxChange, checkedImagesNames }) => {
  return (
    <Card square>
      <List dense disablePadding>
        {images.map(({ name }, index) => (
          <ListItem key={name + index} divider={index !== images.length - 1}>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <Checkbox
                onChange={() => onCheckboxChange(name)}
                checked={name in checkedImagesNames}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

ImageCheckList.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  checkedImagesNames: PropTypes.object,
  onCheckboxChange: PropTypes.func,
};
ImageCheckList.defaultProps = {
  onCheckboxChange() {},
  checkedImagesNames: {},
};

export default ImageCheckList;
