import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    width: '12px',
    height: '12px',
    position: 'absolute',
    '&:hover': { transform: 'scale(1.25)', transition: 'transform .3s' },
    top: 'calc(50% - 6px)',
    // Center dots within the wrapper
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dot: {
    borderRadius: '50%',
  },
  fullKeyFrameDot: {
    height: '10px',
    width: '10px',
  },
  fullDot: {
    height: '7px',
    width: '7px',
  },
  emptyDot: {
    height: '5px',
    width: '5px',
    backgroundColor: '#fff',
    border: '2px solid',
  },
  emptyKeyFrameDot: {
    height: '8px',
    width: '8px',
    backgroundColor: '#fff',
    border: '2px solid',
  },
});

// Use forwardRef to allow for Material UI Tooltip to use this as a child.
const ImageFrameDot = React.forwardRef(
  ({ isKeyframe, isEmpty, onMouseOver, onMouseLeave, color, position, onClick }, ref) => {
    const classes = useStyles();
    let className = classes.fullDot;
    if (isEmpty) {
      className = isKeyframe ? classes.emptyKeyFrameDot : classes.emptyDot;
    } else if (isKeyframe) {
      className = classes.fullKeyFrameDot;
    }
    const getBackgroundColor = () => (isEmpty ? '#fff' : color);
    const getBorderColor = () => (!isEmpty ? '#fff' : color);

    return (
      <div
        ref={ref}
        style={{
          left: `calc(${position} - 6px)`,
        }}
        className={classes.wrapper}
        onClick={onClick}
        // These two functions allow the hover event to bubble to the tooltip parent
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={`${classes.dot} ${className}`}
          style={{
            backgroundColor: getBackgroundColor(),
            borderColor: getBorderColor(),
          }}
        />
      </div>
    );
  }
);

ImageFrameDot.propTypes = {
  isKeyframe: PropTypes.bool,
  isEmpty: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

ImageFrameDot.defaultProps = {
  isKeyframe: false,
  isEmpty: false,
  onMouseOver() {},
  onMouseLeave() {},
  onClick() {},
};

export default ImageFrameDot;
