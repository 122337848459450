import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';

const NumberField = ({
  value,
  label,
  suffix,
  onChange,
  onEnterPress,
  additionalValidation,
  allowPositive,
  allowNegative,
  allowZero,
  allowDecimals,
  allowEmpty,
  fullWidth,
}) => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (value) => {
    const cleanedValue = cleanValue(value);
    const isValid = inputIsValid(cleanedValue);
    setIsValid(isValid);
    onChange(cleanedValue, isValid);
  };

  const cleanValue = (value) => {
    const numValue = parseNumber(value, 10);
    if (!isNaN(numValue)) {
      return numValue;
    }
    if (value == null) {
      return '';
    }

    return value;
  };

  const inputIsValid = (value) => {
    if (allowEmpty && value === '') {
      return true;
    }

    if (isNaN(parseNumber(value, 10))) {
      return false;
    }

    if (!allowPositive && value > 0) {
      return false;
    }

    if (!allowZero && value === 0) {
      return false;
    }

    if (!allowNegative && value < 0) {
      return false;
    }

    if (!allowDecimals && (value % 1 !== 0 || value.toString().includes('.'))) {
      return false;
    }

    if (!additionalValidation(value)) {
      return false;
    }

    return true;
  };

  const parseNumber = (num) => (allowDecimals ? parseFloat(num) : parseInt(num));

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onEnterPress(isValid);
    }
  };

  return (
    <TextField
      label={label}
      onChange={(e) => handleInputChange(e.target.value)}
      onKeyPress={(e) => handleKeyPress(e)}
      value={value}
      error={!isValid}
      InputProps={
        suffix !== ''
          ? { endAdornment: <InputAdornment position="end">{suffix}</InputAdornment> }
          : {}
      }
      fullWidth={fullWidth}
    />
  );
};

NumberField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  additionalValidation: PropTypes.func,
  allowPositive: PropTypes.bool.isRequired,
  allowZero: PropTypes.bool,
  allowNegative: PropTypes.bool,
  allowDecimals: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

NumberField.defaultProps = {
  value: null,
  suffix: '',
  onChange() {},
  onEnterPress() {},
  additionalValidation: () => true,
  allowZero: false,
  allowNegative: false,
  allowDecimals: false,
  allowEmpty: false,
  fullWidth: false,
};

export default NumberField;
