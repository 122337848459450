import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import ColorTextField from './UI/ColorTextField';
import NumberField from './UI/NumberField';

class CanvasModifier extends React.Component {
  state = {
    width: null,
    height: null,
    backgroundColor: null,
    inputIsValid: {
      width: true,
      height: true,
      backgroundColor: true,
    },
  };

  handleWidthChange = (width, isValid) => {
    this.setState(prevState => ({
      width,
      inputIsValid: { ...prevState.inputIsValid, width: isValid },
    }));
  };

  handleHeightChange = (height, isValid) => {
    this.setState(prevState => ({
      height,
      inputIsValid: { ...prevState.inputIsValid, height: isValid },
    }));
  };

  handleBackgroundColorChange = (backgroundColor, isValid) => {
    this.setState(prevState => ({
      backgroundColor,
      inputIsValid: { ...prevState.inputIsValid, backgroundColor: isValid },
    }));
  };

  canSubmit = () => Object.values(this.state.inputIsValid).every(Boolean);

  handleClick = () => {
    this.props.onChange(
      this.state.width != null ? this.state.width : this.props.width,
      this.state.height != null ? this.state.height : this.props.height,
      this.state.backgroundColor != null ? this.state.backgroundColor : this.props.backgroundColor
    );
  };

  render() {
    return (
      <Grid container spacing={1} direction="column" justify="flex-end" alignItems="flex-start">
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <NumberField
                label="Width"
                onChange={this.handleWidthChange}
                value={this.state.width !== null ? this.state.width : this.props.width}
                allowPositive
              />
            </Grid>
            <Grid item>
              <NumberField
                label="Height"
                onChange={this.handleHeightChange}
                value={this.state.height !== null ? this.state.height : this.props.height}
                allowPositive
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ColorTextField
            label="Background Color"
            onColorChange={this.handleBackgroundColorChange}
            value={
              this.state.backgroundColor !== null
                ? this.state.backgroundColor
                : this.props.backgroundColor
            }
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClick}
            disabled={!this.canSubmit()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  }
}

CanvasModifier.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
CanvasModifier.defaultProps = {
  width: 0,
  height: 0,
  backgroundColor: '',
  onChange() {},
};

export default CanvasModifier;
