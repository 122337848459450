import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  tooltip: {
    // Make transparent bg and no padding so we only see the alert
    backgroundColor: 'rgba(0, 0, 0, 0)',
    padding: '0px',
    filter: 'drop-shadow(0 0 2px black)',
  },
});

const TooltipAlert = ({ children, severity, message, placement }) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<Alert severity={severity}>{message}</Alert>}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

TooltipAlert.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  placement: PropTypes.oneOf([
    'bottom',
    'left',
    'right',
    'top',
    'bottom-start',
    'left-start',
    'right-start',
    'top-start',
    'bottom-end',
    'left-end',
    'right-end',
    'top-end',
  ]),
  message: PropTypes.string.isRequired,
};

TooltipAlert.defaultProps = {
  placement: 'bottom',
  severity: 'success',
};

export default TooltipAlert;
