export const TOOLS = {
  NONE: 'none',
  IMAGES: 'images',
  CANVAS: 'canvas',
  PARTIFY: 'partify',
  INTENSIFY: 'intensify',
  TRAIN: 'train',
  SLIDE: 'translate',
  INFINITY: 'infinity',
  LAYERS: 'layers',
  APPEND: 'append',
  FRAMES: 'frames',
};

export const toolData = {
  [TOOLS.NONE]: { title: '', showImageList: false, canSelectMultiple: true },
  [TOOLS.IMAGES]: { title: 'Images', showImageList: false, canSelectMultiple: true },
  [TOOLS.CANVAS]: { title: 'Canvas', showImageList: false, canSelectMultiple: true },
  [TOOLS.PARTIFY]: { title: 'Partify', showImageList: true, canSelectMultiple: true },
  [TOOLS.INTENSIFY]: { title: 'Intensify', showImageList: true, canSelectMultiple: true },
  [TOOLS.TRAIN]: { title: 'Train', showImageList: true, canSelectMultiple: true },
  [TOOLS.SLIDE]: { title: 'Slide', showImageList: true, canSelectMultiple: true },
  [TOOLS.INFINITY]: { title: 'Infinity', showImageList: true, canSelectMultiple: true },
  [TOOLS.LAYERS]: { title: 'Layers', showImageList: false, canSelectMultiple: true },
  [TOOLS.APPEND]: { title: 'Append', showImageList: true, canSelectMultiple: true },
  [TOOLS.FRAMES]: { title: 'Frames', showImageList: true, canSelectMultiple: false },
};
