import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { imageShape } from '../shapes/ImageShapes';
import { Delete } from '@material-ui/icons';
import NumberField from './UI/NumberField';

class ImageForm extends React.Component {
  state = {
    x: null,
    y: null,
    width: null,
    height: null,
    inputIsValid: {
      x: true,
      y: true,
      width: true,
      height: true,
    },
  };

  handleInputChange = (value, field, isValid) => {
    this.setState(prevState => ({
      [field]: value,
      inputIsValid: { ...prevState.inputIsValid, [field]: isValid },
    }));
  };

  handleSubmit = () => {
    if (!this.canSubmit()) {
      return;
    }
    this.props.onSubmit(this.props.image.name, {
      x: this.getX(),
      y: this.getY(),
      width: this.getWidth(),
      height: this.getHeight(),
    });
  };

  getX = () => {
    return this.state.x !== null ? this.state.x : this.props.image.x;
  };

  getY = () => {
    return this.state.y !== null ? this.state.y : this.props.image.y;
  };

  getWidth = () => {
    return this.state.width !== null ? this.state.width : this.props.image.frames[0].width;
  };

  getHeight = () => {
    return this.state.height !== null ? this.state.height : this.props.image.frames[0].height;
  };

  canSubmit = () => Object.values(this.state.inputIsValid).every(Boolean);

  render() {
    return (
      <>
        <List dense disablePadding>
          <ListItem divider>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary={this.props.image.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => this.props.onDeleteClick(this.props.image.name)}
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Box m={1}>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <NumberField
                label="Width"
                onChange={(width, isValid) => this.handleInputChange(width, 'width', isValid)}
                onEnterPress={this.handleSubmit}
                value={this.getWidth()}
                allowPositive
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                label="Height"
                onChange={(height, isValid) => this.handleInputChange(height, 'height', isValid)}
                onEnterPress={this.handleSubmit}
                value={this.getHeight()}
                allowPositive
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                label="X"
                onChange={(x, isValid) => this.handleInputChange(x, 'x', isValid)}
                onEnterPress={this.handleSubmit}
                value={this.getX()}
                allowPositive
                allowZero
                allowNegative
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                label="Y"
                onChange={(y, isValid) => this.handleInputChange(y, 'y', isValid)}
                onEnterPress={this.handleSubmit}
                value={this.getY()}
                allowPositive
                allowNegative
                allowZero
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

ImageForm.propTypes = {
  image: imageShape.isRequired,
  onSubmit: PropTypes.func,
  onDeleteClick: PropTypes.func,
};
ImageForm.defaultProps = {
  onSubmit() {},
  onDeleteClick() {},
};

export default ImageForm;
