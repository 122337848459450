import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import DraggableImageList from './DraggableImageList';

class Layers extends React.Component {
  state = {
    hoveredItem: null,
  };

  handleDragEnd = reorderedImageNames => {
    const indicesByName = Object.entries(Object.assign({}, reorderedImageNames)).reduce(
      (acc, kvp) => {
        acc[kvp[1]] = kvp[0];
        return acc;
      },
      {}
    );
    const reorderedImages = Array(indicesByName.length);
    this.props.images.forEach(image => {
      reorderedImages[indicesByName[image.name]] = image;
    });

    this.props.onImageChangeStart();
    this.props.onImageChange(reorderedImages);
  };

  render() {
    return this.props.images.length ? (
      <DraggableImageList
        imageNames={this.props.images.map(image => image.name)}
        onDragEnd={this.handleDragEnd}
      />
    ) : (
      <Typography>
        You will be able change the order of images here, but first you have to add some!
      </Typography>
    );
  }
}

Layers.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  onImageChange: PropTypes.func,
  onImageChangeStart: PropTypes.func,
};
Layers.defaultProps = {
  onImageChange() {},
  onImageChangeStart() {},
  onImageRemoval() {},
  checkedImagesNames: {},
};

export default Layers;
