import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Divider, Grid, makeStyles } from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import NumberField from './UI/NumberField';
import TooltipAlert from './UI/TooltipAlert';

const useStyles = makeStyles({
  frameOrderText: {
    overflowWrap: 'anywhere',
  },
});

const Frames = ({
  image,
  selectedFrameIndices,
  frameOrder,
  onSubmit,
  onPreviewClick,
  onPreviewBackClick,
  isPreviewing,
}) => {
  const classes = useStyles();
  const selectedFrames =
    Object.keys(image).length > 0 && selectedFrameIndices.length > 0
      ? selectedFrameIndices.map((index) => image.frames[index])
      : [];
  const [delay, setDelay] = useState(null);
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const [isValid, setIsValid] = useState({});

  const getDelay = () => {
    if (delay != null) {
      return delay;
    }

    return selectedFrames.length > 0 &&
      selectedFrames.every((frame) => frame.delay === selectedFrames[0].delay)
      ? selectedFrames[0].delay
      : '';
  };
  const getX = () => {
    if (x != null) {
      return x;
    }
    // Frame position is stored relative to the overall image position, but we want to
    // display it as absolute to the user
    return selectedFrames.length > 0 &&
      selectedFrames.every((frame) => frame.x === selectedFrames[0].x)
      ? image.x + selectedFrames[0].x
      : '';
  };
  const getY = () => {
    if (y != null) {
      return y;
    }
    // Frame position is stored relative to the overall image position, but we want to
    // display it as absolute to the user
    return selectedFrames.length > 0 &&
      selectedFrames.every((frame) => frame.y === selectedFrames[0].y)
      ? image.y + selectedFrames[0].y
      : '';
  };
  const handleDelayChange = (delay, isValid) => {
    setDelay(delay);
    setIsValid({ ...isValid, delay: isValid });
  };

  const handleXChange = (x, isValid) => {
    setX(x);
    setIsValid({ ...isValid, x: isValid });
  };

  const handleYChange = (y, isValid) => {
    setY(y);
    setIsValid({ ...isValid, y: isValid });
  };

  const getIsValid = () => Object.values(isValid).every((val) => val);
  return Object.keys(image).length > 0 ? (
    <>
      <Box marginTop="10px" marginBottom="10px">
        <Divider />
      </Box>
      {!isPreviewing && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            marginBottom="10px"
            textAlign="left"
          >
            <TooltipAlert
              placement="right"
              severity="info"
              message="To select frames, click them in the window to the right!"
            >
              <Box width="100%" marginBottom="10px">
                <Typography align="left" variant="overline">
                  Selected Frames:
                </Typography>

                <Typography align="left">
                  {selectedFrameIndices.length > 0
                    ? selectedFrameIndices
                        .map((index) => index + 1)
                        .sort((a, b) => a - b)
                        .join(', ')
                    : 'Please select one or more frames to edit their details'}
                </Typography>
              </Box>
            </TooltipAlert>
            <Box width="100%" marginBottom="10px">
              {selectedFrameIndices.length > 0 && (
                <Grid container direction="column" spacing={2} alignItems="flex-start">
                  <Grid item classes={{ root: classes.gridItem }}>
                    <NumberField
                      label="Wait before next frame"
                      suffix="ms"
                      value={getDelay()}
                      onChange={handleDelayChange}
                      allowPositive
                      allowZero
                      fullWidth
                    />
                  </Grid>
                  <Grid item classes={{ root: classes.gridItem }}>
                    <NumberField
                      label="X Position"
                      suffix="px"
                      value={getX()}
                      onChange={handleXChange}
                      allowPositive
                      allowZero
                      fullWidth
                    />
                  </Grid>
                  <Grid item classes={{ root: classes.gridItem }}>
                    <NumberField
                      label="Y Position"
                      suffix="px"
                      value={getY()}
                      onChange={handleYChange}
                      allowPositive
                      allowZero
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
            <TooltipAlert
              placement="right"
              severity="info"
              message="To reorder frames, drag them around in the window to the right!"
            >
              <Box width="100%" marginBottom="10px">
                <Typography align="left" variant="overline">
                  Frame Order:
                </Typography>
                <Typography align="left" classes={{ root: classes.frameOrderText }}>
                  {frameOrder.map((index) => index + 1).join(' ➡️ ')}
                </Typography>
              </Box>
            </TooltipAlert>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={() => onPreviewClick(getDelay(), getX(), getY())}
              variant="contained"
              color="primary"
              disabled={!getIsValid()}
            >
              Preview
            </Button>
            <Button
              onClick={() => onSubmit(getDelay(), getX(), getY())}
              variant="contained"
              color="primary"
              disabled={!getIsValid()}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
      {isPreviewing && (
        <Button onClick={() => onPreviewBackClick(delay, x, y)} variant="contained" color="primary">
          Back
        </Button>
      )}
    </>
  ) : (
    <Typography>
      Here you can modify the properties of a single frame, but first you need to add an image!
    </Typography>
  );
};

Frames.propTypes = {
  image: imageShape.isRequired,
  selectedFrameIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  frameOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSubmit: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onPreviewBackClick: PropTypes.func,
  isPreviewing: PropTypes.bool,
};

Frames.defaultProps = {
  onSubmit() {},
  onPreviewClick() {},
  onPreviewBackClick() {},
  isPreviewing: false,
};

export default Frames;
