import Pica from 'pica';

const pica = Pica();

export function resizeImage(imageOrCanvas, newWidth, newHeight) {
  // Pica requires an ouput canvas, so make a temporary one
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = newWidth;
  tempCanvas.height = newHeight;
  return new Promise((resolve, reject) => {
    pica
      .resize(imageOrCanvas, tempCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
        alpha: true,
      })
      .then(() => {
        const url = tempCanvas.toDataURL();
        const image = new Image();
        image.onload = (e) => {
          resolve(image);
        };
        image.src = url;
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resizeFrame(frame, newWidth = null, newHeight = null) {
  return resizeAllFrames([frame], newWidth, newHeight).then((frames) => frames[0]);
}

export function resizeAllFrames(frames, newWidth = null, newHeight = null) {
  return resizeAllImages(
    frames.map((frame) => ({
      ...frame,
      width: newWidth !== null ? newWidth : frame.width,
      height: newHeight !== null ? newHeight : frame.height,
    }))
  );
}

export function resizeAllFramesWithScale(frames, scale) {
  return resizeAllImages(
    frames.map((frame) => ({
      ...frame,
      width: frame.width * (scale / 100),
      height: frame.height * (scale / 100),
    }))
  );
}

function resizeAllImages(frames) {
  const resizePromises = [];
  // Collect all resize promises
  frames.forEach((frame) => {
    resizePromises.push(
      new Promise((resolve, reject) => {
        resizeImage(frame.image, frame.width, frame.height)
          .then((image) =>
            resolve({
              ...frame,
              image,
              url: [image.src], // TODO remove. Keeping until everything uses images
            })
          )
          .catch((error) => reject(error));
      })
    );
  });
  return Promise.all(resizePromises);
}
