import React from 'react';
import PropTypes from 'prop-types';
import convert from 'color-convert';
import {
  recolorFramesWithTint,
  recolorFramesWithColorReplace,
  getColorFromPixel,
} from '../image_utilities/Recolor';
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Slider,
  styled,
  Checkbox,
} from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import ColorTextField from './UI/ColorTextField';
import NumberField from './UI/NumberField';
import { Alert } from '@material-ui/lab';

const PARTIFY_TYPES = {
  TINT: 'tint',
  COLOR_REPLACE: 'color replace',
};

const FullWidthGrid = styled(Grid)({
  width: '100%',
  textAlign: 'left',
});

class Partify extends React.Component {
  state = {
    colorToReplace: '',
    threshold: 15,
    showResize: false,
    partifyType: PARTIFY_TYPES.TINT,
    delay: 40,
    tintPercentage: 50,
    ignoreTransparency: false,
    showTransparencyTooltip: false,
    inputIsValid: {
      delay: true,
      threshold: true,
      colorToReplace: true,
    },
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.selectedPixel.length > 0 &&
      prevProps.selectedPixel !== this.props.selectedPixel
    ) {
      // Reset color to replace validation if we're getting a new color from the eyedropper
      this.setState(prevState => ({
        colorToReplace: getColorFromPixel(this.props.selectedPixel),
        inputIsValid: { ...prevState.inputIsValid, colorToReplace: true },
      }));
    }
  };

  handlePartifyClick = () => {
    this.props.onImageChangeStart();
    // Update delay on all images
    const images = this.props.images.map(image => ({
      ...image,
      frames: image.frames.map(frame => ({
        ...frame,
        delay: this.state.delay,
      })),
    }));

    // Do recoloring
    let promises = [];
    switch (this.state.partifyType) {
      case PARTIFY_TYPES.TINT:
        promises = images.map(
          image =>
            new Promise(resolve => {
              recolorFramesWithTint(
                image.frames,
                this.state.tintPercentage,
                this.state.ignoreTransparency
              ).then(recoloredFrames => resolve({ ...image, frames: recoloredFrames }));
            })
        );
        break;
      case PARTIFY_TYPES.COLOR_REPLACE:
      default:
        const colorToReplaceHsl = convert.hex.hsl(this.state.colorToReplace);
        promises = images.map(
          image =>
            new Promise(resolve => {
              recolorFramesWithColorReplace(
                image.frames,
                colorToReplaceHsl,
                this.state.threshold
              ).then(recoloredFrames => resolve({ ...image, frames: recoloredFrames }));
            })
        );
        break;
    }
    Promise.all(promises).then(recoloredImages => this.props.onImageChange(recoloredImages));
  };

  handleRadioChange = e => {
    const value = e.target.value;
    this.setState(prevState => {
      const newState = { partifyType: value };
      if (value === PARTIFY_TYPES.TINT) {
        // Reset threshold and colorToReplace validation because we don't care about it's value for tint
        newState.inputIsValid = {
          ...prevState.inputIsValid,
          threshold: true,
          colorToReplace: true,
        };
      }
      return newState;
    });
  };

  handleThresholdChange = (threshold, isValid) => {
    this.setState(prevState => ({
      threshold,
      inputIsValid: {
        ...prevState.inputIsValid,
        threshold: isValid,
      },
    }));
  };

  handleColorToReplaceChange = (color, isValid) => {
    this.setState(prevState => ({
      colorToReplace: color,
      inputIsValid: {
        ...prevState.inputIsValid,
        colorToReplace: isValid,
      },
    }));
  };

  handleEyeDropperClick = () => {
    this.setState({ colorToReplace: '' });
    this.props.onEyeDropperClick();
  };

  handleDelayChange = (delay, isValid) => {
    this.setState(prevState => ({
      delay,
      inputIsValid: {
        ...prevState.inputIsValid,
        delay: isValid,
      },
    }));
  };

  handleTintPercentageChange = (e, tintPercentage) => this.setState({ tintPercentage });

  handleTransparencyChange = e => this.setState({ ignoreTransparency: e.target.checked });

  handleCheckboxMouseEnter = () => this.setState({ showTransparencyTooltip: true });

  handleCheckboxMouseLeave = () => this.setState({ showTransparencyTooltip: false });

  canSubmit = () => Object.values(this.state.inputIsValid).every(Boolean);

  render() {
    return this.props.images.length > 0 ? (
      <>
        <Grid container spacing={1} direction="column" justify="flex-end" alignItems="flex-start">
          <Grid item xs={12}>
            <RadioGroup
              aria-label="partify-type"
              name="partify-type"
              value={this.state.partifyType}
              onChange={this.handleRadioChange}
            >
              <FormControlLabel
                value={PARTIFY_TYPES.TINT}
                control={<Radio color="primary" />}
                label="Tint"
                labelPlacement="end"
              />
              <FormControlLabel
                value={PARTIFY_TYPES.COLOR_REPLACE}
                control={<Radio color="primary" />}
                label="Color Replace"
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
          <FullWidthGrid item>
            <Grid container direction="column" alignItems="flex-start" justify="flex-start">
              <Grid item>
                <NumberField
                  label="Wait Between Frames"
                  suffix="ms"
                  onChange={this.handleDelayChange}
                  value={this.state.delay}
                  allowPositive
                  allowZero
                />
              </Grid>
              {this.state.partifyType === PARTIFY_TYPES.COLOR_REPLACE ? (
                <>
                  <Grid item>
                    <NumberField
                      label="Threshold"
                      onChange={this.handleThresholdChange}
                      value={this.state.threshold}
                      allowPositive
                      allowZero
                    />
                  </Grid>
                  <Grid item>
                    <ColorTextField
                      label="Color to replace"
                      onColorChange={this.handleColorToReplaceChange}
                      value={this.state.colorToReplace}
                      showEyeDropper
                      onEyeDropperClick={this.handleEyeDropperClick}
                      eyeDropperIsEnabled={this.props.eyeDropperIsEnabled}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <FullWidthGrid item>
                    <Typography variant="caption" color="textSecondary" align="left">
                      Intensity
                    </Typography>
                    <Slider
                      onChange={this.handleTintPercentageChange}
                      value={this.state.tintPercentage}
                      valueLabelDisplay="auto"
                    />
                  </FullWidthGrid>
                  <FullWidthGrid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.ignoreTransparency}
                          onChange={this.handleTransparencyChange}
                        />
                      }
                      label="Ignore Transparency"
                      onMouseEnter={this.handleCheckboxMouseEnter}
                      onMouseLeave={this.handleCheckboxMouseLeave}
                    />
                    {this.state.showTransparencyTooltip && (
                      <Alert severity="info">
                        This setting can help prevent partially transparent images from showing
                        artifacts being "partied".
                      </Alert>
                    )}
                  </FullWidthGrid>
                </>
              )}
            </Grid>
          </FullWidthGrid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handlePartifyClick}
              disabled={!this.canSubmit()}
            >
              Partify
            </Button>
          </Grid>
        </Grid>
      </>
    ) : (
      <Typography>
        Here you can recolor an image with party colors, but first you need to add one!
      </Typography>
    );
  }
}

Partify.propTypes = {
  images: PropTypes.arrayOf(imageShape),
  // uint 8 clamped array
  selectedPixel: PropTypes.objectOf(PropTypes.number),
  onImageChange: PropTypes.func.isRequired,
  onImageChangeStart: PropTypes.func,
  onEyeDropperClick: PropTypes.func,
  eyeDropperIsEnabled: PropTypes.bool,
};
Partify.defaultProps = {
  images: [],
  selectedPixel: [],
  onImageChangeStart() {},
  onEyeDropperClick() {},
  eyeDropperIsEnabled: false,
};

export default Partify;
