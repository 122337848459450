import React from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow, Save, Undo, Redo, SkipNext, SkipPrevious } from '@material-ui/icons';
import ImageFrameSlider from './ImageFrameSlider';
import { Card, Box, Grid } from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import ToolTippedIconButton from './UI/ToolTippedIconButton';

const Player = ({
  images,
  totalNumFrames,
  absoluteCurrentIndex,
  onClickPlay,
  onClickSave,
  onFrameChange,
  isPlaying,
  disableButtons,
  onUndoClick,
  onRedoClick,
  canUndo,
  canRedo,
  onClickNext,
  onClickPrevious,
  onFrameClick,
}) => {
  return (
    <Card>
      <Grid container>
        <Grid item xs={1}>
          <Box display="flex" flexDirection="row">
            <ToolTippedIconButton
              // eslint-disable-next-line
              iconButtonProps={{ onClick: onUndoClick, ['aria-label']: 'Undo' }}
              tooltipProps={{ title: 'Undo', placement: 'bottom' }}
              disabled={disableButtons || !canUndo}
            >
              <Undo />
            </ToolTippedIconButton>
            <ToolTippedIconButton
              // eslint-disable-next-line
              iconButtonProps={{ onClick: onRedoClick, ['aria-label']: 'Redo' }}
              tooltipProps={{ title: 'Redo', placement: 'bottom' }}
              disabled={disableButtons || !canRedo}
            >
              <Redo />
            </ToolTippedIconButton>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <ToolTippedIconButton
            iconButtonProps={{
              variant: 'text',
              size: 'small',
              // eslint-disable-next-line
              ['aria-label']: 'Previous Frame',
              onClick: onClickPrevious,
            }}
            tooltipProps={{ title: 'Previous Frame', placement: 'bottom' }}
          >
            <SkipPrevious />
          </ToolTippedIconButton>
          <ToolTippedIconButton
            tooltipProps={{
              title: isPlaying ? 'Pause' : 'Play',
              placement: 'bottom',
            }}
            iconButtonProps={{
              onClick: onClickPlay,
              // eslint-disable-next-line
              ['aria-label']: 'play/pause',
            }}
            disabled={disableButtons}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
          </ToolTippedIconButton>
          <ToolTippedIconButton
            tooltipProps={{
              title: 'Next Frame',
              placement: 'bottom',
            }}
            iconButtonProps={{
              variant: 'text',
              size: 'small',
              // eslint-disable-next-line
              ['aria-label']: 'next frame',
              onClick: onClickNext,
            }}
          >
            <SkipNext />
          </ToolTippedIconButton>
        </Grid>
        <Grid item xs={1}>
          <ToolTippedIconButton
            tooltipProps={{ title: 'Save', placement: 'bottom' }}
            // eslint-disable-next-line
            iconButtonProps={{ onClick: onClickSave, ['aria-label']: 'save' }}
            disabled={disableButtons}
          >
            <Save />
          </ToolTippedIconButton>
        </Grid>
      </Grid>
      {images.map((image, index) => (
        <ImageFrameSlider
          key={index}
          startIndex={image.startIndex}
          imageName={image.name}
          imageFrameCount={image.frames.length}
          numFrames={totalNumFrames}
          onClickIncrease={() => {
            onFrameChange(index, 1);
          }}
          onClickDecrease={() => onFrameChange(index, -1)}
          onFrameClick={(frameIndex) => onFrameClick(image.name, frameIndex)}
          absoluteCurrentIndex={absoluteCurrentIndex}
          emptyFrameIndices={image.frames.reduce((acc, frame, index) => {
            if (!!frame.isEmpty) {
              acc.push(index);
            }
            return acc;
          }, [])}
        />
      ))}
    </Card>
  );
};

Player.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  totalNumFrames: PropTypes.number.isRequired,
  onClickPlay: PropTypes.func,
  onClickSave: PropTypes.func,
  onFrameChange: PropTypes.func,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  absoluteCurrentIndex: PropTypes.number.isRequired,
  isPlaying: PropTypes.bool,
  disableButtons: PropTypes.bool,
  onUndoClick: PropTypes.func,
  onRedoClick: PropTypes.func,
  canUndo: PropTypes.bool,
  canRedo: PropTypes.bool,
  onFrameClick: PropTypes.func,
};

Player.defaultProps = {
  onClickPlay() {},
  onFrameChange() {},
  onClickSave() {},
  isPlaying: true,
  disableButtons: false,
  onUndoClick() {},
  onRedoClick() {},
  canUndo: false,
  canRedo: false,
  onFrameClick() {},
};

export default Player;
