import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItemIcon,
  ListItem,
  ListItemText,
  Grid,
  Tooltip,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/Image';
import interpolate from 'color-interpolate';
import ImageFrameDot from './ImageFrameDot';

const useStyles = makeStyles({
  lineWrapper: {
    position: 'relative',
    width: '100%',
  },
  verticalLine: {
    position: 'absolute',
    height: '100%',
    width: '0px',
    border: '1px solid',
    top: 0,
    // left will be set dynamically
  },
  horizontalLine: {
    position: 'absolute',
    height: '2px',
    background: '#ddd',
    width: '100%',
    // 1px to account for top at 50% rather than middle
    top: 'calc(50% - 1px)',
  },
  sliderRoot: {
    display: 'block',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const colormap = interpolate(['#666', '#ccc']);

const ImageFrameSlider = ({
  numFrames,
  startIndex,
  imageName,
  imageFrameCount,
  onClickIncrease,
  onClickDecrease,
  onFrameClick,
  absoluteCurrentIndex,
  emptyFrameIndices,
}) => {
  const classes = useStyles();
  const linePosition = `calc(${(absoluteCurrentIndex / (numFrames - 1)) * 100}% - 1px)`;
  const getIsKeyframe = (frameIndex) => frameIndex.mod(imageFrameCount) / imageFrameCount === 0;
  const getDotPosition = (index) => `${(index / (numFrames - 1)) * 100}%`;
  const getBackgroundColor = (frameIndex) =>
    colormap(frameIndex.mod(imageFrameCount) / imageFrameCount);
  return (
    <Grid container>
      <Grid item sm={4}>
        <ListItem>
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary={imageName} />
        </ListItem>
      </Grid>
      <Grid item sm={8}>
        <Box display="flex" height="100%">
          <Tooltip title="Move Animation Start Left" placement="top">
            <IconButton
              variant="text"
              size="small"
              aria-label="move animation start left"
              onClick={onClickDecrease}
            >
              <ArrowLeft />
            </IconButton>
          </Tooltip>
          <div className={classes.lineWrapper}>
            <div className={classes.verticalLine} style={{ left: linePosition }} />
            <div className={classes.horizontalLine} />
            {Array.from(Array(numFrames)).map((_, index) => {
              const frameIndex = (index - startIndex).mod(numFrames);
              const trueFrameIndex = (index - startIndex).mod(imageFrameCount);
              return (
                <Tooltip
                  key={`dot${index + 1}`}
                  title={`Edit Frame ${trueFrameIndex + 1}`}
                  enterDelay={0}
                  leaveDelay={200}
                >
                  <ImageFrameDot
                    isKeyframe={getIsKeyframe(frameIndex)}
                    isEmpty={emptyFrameIndices.includes(trueFrameIndex)}
                    color={getBackgroundColor(frameIndex)}
                    position={getDotPosition(index)}
                    onClick={() => onFrameClick(trueFrameIndex)}
                  />
                </Tooltip>
              );
            })}
          </div>
          <Tooltip title="Move Animation Start Right" placement="top">
            <IconButton
              variant="text"
              size="small"
              aria-label="move animation start right"
              onClick={onClickIncrease}
            >
              <ArrowRight />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

ImageFrameSlider.propTypes = {
  startIndex: PropTypes.number.isRequired,
  imageFrameCount: PropTypes.number.isRequired,
  imageName: PropTypes.string.isRequired,
  numFrames: PropTypes.number.isRequired,
  absoluteCurrentIndex: PropTypes.number.isRequired,
  onClickIncrease: PropTypes.func,
  onClickDecrease: PropTypes.func,
  onFrameClick: PropTypes.func,
  emptyFrameIndices: PropTypes.arrayOf(PropTypes.number),
};

ImageFrameSlider.defaultProps = {
  onClickIncrease() {},
  onClickDecrease() {},
  onFrameClick() {},
  emptyFrameIndices: [],
};

export default ImageFrameSlider;
