import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  Box,
  styled,
  Card,
  Divider,
  CardActionArea,
} from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import NumberField from './UI/NumberField';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RadioButtonUnchecked, DragIndicator, ExpandMore, ExpandLess } from '@material-ui/icons';
import TextOverlay from './UI/TextOverlay';
import { emptyFrame } from '../image_utilities/Empty';

const FullWidthGrid = styled(Grid)({
  width: '100%',
});

const AppendCard = styled(Card)({
  marginTop: '5px',
});

const PaddedCardActionArea = styled(CardActionArea)({
  padding: '10px',
});

const Content = styled(Box)({
  padding: '10px',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
});

class Append extends React.Component {
  state = {
    orderedImageNames: [],
    currentStepName: '',
    delays: {},
    loops: {},
    inputIsValid: {
      loops: true,
      delays: true,
    },
    hoveredStep: '',
  };

  componentDidUpdate(prevProps, prevState) {
    // If we added or removed an image, reset ordering
    if (this.props.images.length !== prevState.orderedImageNames.length) {
      this.setState({ orderedImageNames: this.props.images.map((image) => image.name) });
    }
  }

  handleSubmit = () => {
    this.props.onImageChangeStart();
    const framesPerImage = this.props.images.reduce((acc, image) => {
      const curLoops = this.state.loops[image.name] != null ? this.state.loops[image.name] : 1;
      acc[image.name] = image.frames.length * curLoops;
      return acc;
    }, {});
    const numTotalFrames = Object.keys(framesPerImage).reduce(
      (acc, imageName) => acc + framesPerImage[imageName],
      0
    );
    let numFramesBefore = 0;
    const promisePerImage = this.getOrderedImageNames().map((name) => {
      const image = this.props.images.find((image) => image.name === name);
      const numFramesAfter = numTotalFrames - framesPerImage[image.name] - numFramesBefore;
      const beforeFramesPromises =
        numFramesBefore > 0
          ? Array.from(Array(numFramesBefore)).map(() => emptyFrame(image.frames[0]))
          : [];

      const curImageFramesPromises = Array.from(Array(framesPerImage[image.name])).map(
        (_, index) =>
          new Promise((resolve) =>
            resolve({
              ...image.frames[index.mod(image.frames.length)],
              delay:
                this.state.delays[image.name] != null && this.state.delays[image.name] !== ''
                  ? this.state.delays[image.name]
                  : image.frames[index.mod(image.frames.length)].delay,
            })
          )
      );

      const afterFramesPromises =
        numFramesAfter > 0
          ? Array.from(Array(numFramesAfter)).map(() =>
              emptyFrame(image.frames[image.frames.length - 1])
            )
          : [];

      numFramesBefore += framesPerImage[image.name];

      return Promise.all([
        ...beforeFramesPromises,
        ...curImageFramesPromises,
        ...afterFramesPromises,
      ]).then((frames) => ({
        ...image,
        frames,
      }));
    });

    Promise.all(promisePerImage).then((newImages) => this.props.onImageChange(newImages));
  };

  getBlankFrame = (frame) => {
    const newImage = new Image(frame.image.width, frame.image.height);
    return {
      ...frame,
      // empty delay so we don't effect the max delay with a blank frame
      delay: null,
      // Real image but empty so image modification scripts don't have to account for null
      image: newImage,
      url: newImage.src,
      isEmpty: true,
    };
  };

  getOrderedImageNames = () => {
    return this.state.orderedImageNames.length > 0
      ? this.state.orderedImageNames
      : this.props.images.map((image) => image.name);
  };

  getInputIsValid = () => {
    return Object.keys(this.state.inputIsValid).reduce(
      (acc, name) => acc && Object.values(this.state.inputIsValid[name]).every(Boolean),
      true
    );
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      // Dropped somewhere it doesn't belong
      return;
    }

    this.setState({
      orderedImageNames: this.reorder(
        this.getOrderedImageNames(),
        result.source.index,
        result.destination.index
      ),
    });
  };

  reorder = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
    return result;
  };

  getAppendCardBackgroundColor = (snapshot) => (snapshot.isDragging ? 'blanchedAlmond' : '');

  getDragHandleBackgroundColor = (index) =>
    this.state.hoveredStep === index ? 'blanchedAlmond' : '';

  getDroppableListBackgroundColor = (snapshot) => {
    if (snapshot.isDraggingOver) {
      return 'lightgreen';
    }
    // Dragging is happening, but not over the droppable
    if (snapshot.draggingFromThisWith) {
      return 'lightpink';
    }

    return '';
  };

  getTextOverlayColor = (name) =>
    this.state.currentStepName === name ? 'primary' : 'textSecondary';

  showCardContent = (name, snapshot) =>
    this.state.currentStepName === name && !snapshot.isUsingPlaceholder;

  getIndexOfCurrentStep = () => this.getOrderedImageNames().indexOf(this.state.currentStepName);

  render() {
    return this.props.images.length > 1 ? (
      <>
        <Divider variant="middle" />
        <Grid container spacing={1} direction="column" justify="flex-end" alignItems="flex-start">
          <FullWidthGrid item>
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="layers" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ backgroundColor: this.getDroppableListBackgroundColor(snapshot) }}
                  >
                    {this.getOrderedImageNames().map((name, index) => (
                      <React.Fragment key={name}>
                        <Draggable draggableId={name} index={index} x>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <AppendCard
                                variant="outlined"
                                square
                                style={{
                                  backgroundColor: this.getAppendCardBackgroundColor(snapshot),
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <Box
                                    {...provided.dragHandleProps}
                                    display="flex"
                                    alignItems="center"
                                    onMouseEnter={() => this.setState({ hoveredStep: index })}
                                    onMouseMove={() => this.setState({ hoveredStep: index })}
                                    onMouseLeave={() => this.setState({ hoveredStep: '' })}
                                    style={{
                                      backgroundColor: this.getDragHandleBackgroundColor(index),
                                    }}
                                  >
                                    <DragIndicator
                                      color={
                                        this.state.hoveredStep === index ? 'primary' : 'action'
                                      }
                                    />
                                    <TextOverlay
                                      text={index + 1}
                                      textProps={{
                                        color: this.getTextOverlayColor(name),
                                        variant: 'button',
                                      }}
                                      containerProps={{
                                        style: { padding: '5px', paddingLeft: '0px' },
                                      }}
                                    >
                                      <RadioButtonUnchecked
                                        color={
                                          this.state.currentStepName === name ? 'primary' : 'action'
                                        }
                                      />
                                    </TextOverlay>
                                  </Box>
                                  <Divider orientation="vertical" flexItem />
                                  <PaddedCardActionArea
                                    onClick={() =>
                                      this.setState((prevState) => ({
                                        currentStepName:
                                          prevState.currentStepName === name ? '' : name,
                                      }))
                                    }
                                  >
                                    <Box display="flex" justifyContent="space-between">
                                      {/* Hidden left icon to balance the space between flex*/}
                                      <ExpandLess style={{ visibility: 'hidden' }} />
                                      <Typography>{name}</Typography>
                                      {this.state.currentStepName === name ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </Box>
                                  </PaddedCardActionArea>
                                </Box>
                                {this.showCardContent(name, snapshot) && (
                                  <>
                                    <Divider />
                                    <Content>
                                      <Typography variant="h6">Image Details</Typography>
                                      <NumberField
                                        label="Loops"
                                        value={
                                          this.state.loops[name] != null
                                            ? this.state.loops[name]
                                            : ''
                                        }
                                        onChange={(value, isValid) => {
                                          this.setState((prevState) => ({
                                            loops: { ...prevState.loops, [name]: value },
                                            inputIsValid: {
                                              ...prevState.inputIsValid,
                                              name: {
                                                ...prevState.inputIsValid[name],
                                                loops: isValid,
                                              },
                                            },
                                          }));
                                        }}
                                        allowPositive
                                        allowEmpty
                                      />
                                      <NumberField
                                        label="Wait Between Frames"
                                        value={
                                          this.state.delays[name] != null
                                            ? this.state.delays[name]
                                            : ''
                                        }
                                        onChange={(value, isValid) => {
                                          this.setState((prevState) => ({
                                            delays: { ...prevState.delays, [name]: value },
                                            inputIsValid: {
                                              ...prevState.inputIsValid,
                                              name: {
                                                ...prevState.inputIsValid[name],
                                                delays: isValid,
                                              },
                                            },
                                          }));
                                        }}
                                        suffix="ms"
                                        allowPositive
                                        allowEmpty
                                      />
                                    </Content>
                                  </>
                                )}
                              </AppendCard>
                            </div>
                          )}
                        </Draggable>
                      </React.Fragment>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </FullWidthGrid>
          <FullWidthGrid item>
            <Box display="flex" justifyContent="space-between">
              {this.getIndexOfCurrentStep() > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!this.getInputIsValid()}
                  onClick={() =>
                    this.setState((prevState) => ({
                      currentStepName: this.state.orderedImageNames[
                        this.getIndexOfCurrentStep() - 1
                      ],
                    }))
                  }
                >
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={!this.getInputIsValid()}
                onClick={
                  this.getIndexOfCurrentStep() < this.getOrderedImageNames().length - 1 &&
                  this.state.currentStepName !== ''
                    ? () =>
                        this.setState((prevState) => ({
                          currentStepName:
                            prevState.orderedImageNames[this.getIndexOfCurrentStep() + 1],
                        }))
                    : this.handleSubmit
                }
              >
                {this.getIndexOfCurrentStep() < this.getOrderedImageNames().length - 1 &&
                this.state.currentStepName !== ''
                  ? 'Next'
                  : 'Submit'}
              </Button>
            </Box>
          </FullWidthGrid>
        </Grid>
      </>
    ) : (
      <Typography>
        You will be able append images together here so they play one after the other, but first you
        have to add at least two!
      </Typography>
    );
  }
}

Append.propTypes = {
  images: PropTypes.arrayOf(imageShape),
  onImageChange: PropTypes.func.isRequired,
  onImageChangeStart: PropTypes.func,
};
Append.defaultProps = {
  images: [],
  onImageChange() {},
  onImageChangeStart() {},
};

export default Append;
