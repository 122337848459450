import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { translate, INVALID_PARAMETER_EXCEPTION_NAME } from '../image_utilities/Translate';
import { imageShape } from '../shapes/ImageShapes';
import NumberField from './UI/NumberField';

class Slide extends React.Component {
  state = {
    startPosition: { x: 0, y: 0 },
    minimumEndPosition: { x: 0, y: 0 },
    velocity: { x: 0, y: 0 },
    acceleration: { x: 0, y: 0 },
    delay: 40,
    inputIsValid: {
      startPositionX: true,
      startPositionY: true,
      endPositionX: true,
      endPositionY: true,
      velocityX: true,
      velocityY: true,
      accelerationX: true,
      accelerationY: true,
      delay: true,
    },
  };

  handleClick = () => {
    this.props.onImageChangeStart();
    const promises = this.props.images.map(
      image =>
        new Promise(resolve => {
          resolve(
            translate(
              image,
              this.state.startPosition,
              this.state.velocity,
              this.state.acceleration,
              this.state.minimumEndPosition,
              this.state.delay
            )
          );
        })
    );
    Promise.all(promises)
      .then(modifiedImages => {
        this.props.onImageChange(modifiedImages);
      })
      .catch(e => {
        if ((e.name = INVALID_PARAMETER_EXCEPTION_NAME)) {
          this.props.onError(e.message);
        } else {
          // Make sure we don't display JS errors to the user
          this.props.onError('An error occurred. Unable to translate image.');
        }
        // Noop to stop loading
        this.props.onImageChange(this.props.images);
      });
  };

  handleStartPositionChange = (value, isValid, dimension) => {
    this.setState(prevState => ({
      startPosition: { ...prevState.startPosition, [dimension]: value },
      inputIsValid: {
        ...prevState.inputIsValid,
        ['startPosition' + dimension.toUpperCase()]: isValid,
      },
    }));
  };

  handleEndPositionChange = (value, isValid, dimension) => {
    this.setState(prevState => ({
      minimumEndPosition: { ...prevState.minimumEndPosition, [dimension]: value },
      inputIsValid: {
        ...prevState.inputIsValid,
        ['endPosition' + dimension.toUpperCase()]: isValid,
      },
    }));
  };

  handleVelocityChange = (value, isValid, dimension) => {
    this.setState(prevState => ({
      velocity: { ...prevState.velocity, [dimension]: value },
      inputIsValid: { ...prevState.inputIsValid, ['velocity' + dimension.toUpperCase()]: isValid },
    }));
  };

  handleAccelerationChange = (value, isValid, dimension) => {
    this.setState(prevState => ({
      acceleration: { ...prevState.acceleration, [dimension]: value },
      inputIsValid: {
        ...prevState.inputIsValid,
        ['acceleration' + dimension.toUpperCase()]: isValid,
      },
    }));
  };

  handleDelayChange = (value, isValid) => {
    this.setState(prevState => ({
      delay: value,
      inputIsValid: { ...prevState.inputIsValid, delay: isValid },
    }));
  };

  canSubmit = () => Object.values(this.state.inputIsValid).every(Boolean);

  render() {
    return this.props.images.length ? (
      <Grid container spacing={1} direction="column" justify="flex-end" alignItems="flex-start">
        <Grid item>
          <Grid container spacing={2} direction="column" alignItems="flex-start">
            <Grid item>
              <Typography align="left">Start Position</Typography>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  <NumberField
                    label="x"
                    onChange={(value, isValid) =>
                      this.handleStartPositionChange(value, isValid, 'x')
                    }
                    value={this.state.startPosition.x}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="y"
                    onChange={(value, isValid) =>
                      this.handleStartPositionChange(value, isValid, 'y')
                    }
                    value={this.state.startPosition.y}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography align="left">End Position</Typography>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  <NumberField
                    label="x"
                    onChange={(value, isValid) => this.handleEndPositionChange(value, isValid, 'x')}
                    value={this.state.minimumEndPosition.x}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="y"
                    onChange={(value, isValid) => this.handleEndPositionChange(value, isValid, 'y')}
                    value={this.state.minimumEndPosition.y}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography align="left">Velocity</Typography>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  <NumberField
                    label="x"
                    suffix="px/fr"
                    onChange={(value, isValid) => this.handleVelocityChange(value, isValid, 'x')}
                    value={this.state.velocity.x}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="y"
                    suffix="px/fr"
                    onChange={(value, isValid) => this.handleVelocityChange(value, isValid, 'y')}
                    value={this.state.velocity.y}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography align="left">Acceleration</Typography>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  <NumberField
                    label="x"
                    suffix="px/fr/fr"
                    onChange={(value, isValid) =>
                      this.handleAccelerationChange(value, isValid, 'x')
                    }
                    value={this.state.acceleration.x}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="y"
                    suffix="px/fr/fr"
                    onChange={(value, isValid) =>
                      this.handleAccelerationChange(value, isValid, 'y')
                    }
                    value={this.state.acceleration.y}
                    allowPositive
                    allowZero
                    allowNegative
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <NumberField
                label="Wait Between Frames"
                suffix="ms"
                onChange={this.handleDelayChange}
                value={this.state.delay}
                allowPositive
                allowZero
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            disabled={!this.canSubmit()}
            variant="contained"
            color="primary"
            onClick={this.handleClick}
          >
            Translate
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Typography>
        Here you can make your image slide around the canvas, but first you need to add one!
      </Typography>
    );
  }
}

Slide.propTypes = {
  images: PropTypes.arrayOf(imageShape),
  onImageChange: PropTypes.func.isRequired,
  onImageChangeStart: PropTypes.func,
  onError: PropTypes.func,
};
Slide.defaultProps = {
  images: [],
  onImageChange() {},
  onImageChangeStart() {},
  onError() {},
};

export default Slide;
