import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { ArrowRight, ArrowLeft, ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

const styles = {
  hovering: {
    border: '2px dashed rgba(0,0,0,.5)',
  },
  dragging: {
    backgroundColor: 'rgba(0,0,0,.25)',
  },
  overlay: {
    position: 'absolute',
    display: 'inline-block',
  },
  arrowRight: {
    right: 0,
    top: 'calc(50% - 17.5px)',
    position: 'absolute',
  },
  arrowLeft: {
    left: 0,
    top: 'calc(50% - 17.5px)',
    position: 'absolute',
  },
  arrowUp: {
    left: 'calc(50% - 17.5px)',
    top: 0,
    position: 'absolute',
  },
  arrowDown: {
    left: 'calc(50% - 17.5px)',
    bottom: 0,
    position: 'absolute',
  },
};

class ImageOverlay extends React.Component {
  state = {
    // Seed with image position on render, but let draggable control position afterward
    positionStyle: { x: this.props.initialX, y: this.props.initialY },
    isHovering: false,
    isDragging: false,
  };

  handleDragStop = (e, data) => {
    this.setState({ isDragging: false });
    this.props.onDragEnd(data.x, data.y);
  };

  render() {
    return (
      <Draggable
        onStart={() => this.setState({ isDragging: true })}
        onStop={this.handleDragStop}
        position={{ x: this.props.x, y: this.props.y }}
      >
        <div
          style={{
            ...styles.overlay,
            left: this.state.positionStyle.x,
            top: this.state.positionStyle.y,
            width: this.props.width,
            height: this.props.height,
            ...(this.state.isHovering && styles.hovering),
            ...(this.state.isDragging && styles.dragging),
          }}
          onMouseEnter={() => this.setState({ isHovering: true })}
          onMouseLeave={() => this.setState({ isHovering: false })}
          onMouseMove={() => this.setState({ isHovering: true })}
        >
          {(this.state.isHovering || this.state.isDragging) && (
            <>
              <ArrowRight
                color={this.state.isDragging ? 'primary' : 'secondary'}
                style={styles.arrowRight}
                fontSize="large"
              />
              <ArrowLeft
                color={this.state.isDragging ? 'primary' : 'secondary'}
                style={styles.arrowLeft}
                fontSize="large"
              />
              <ArrowDropUp
                color={this.state.isDragging ? 'primary' : 'secondary'}
                style={styles.arrowUp}
                fontSize="large"
              />
              <ArrowDropDown
                color={this.state.isDragging ? 'primary' : 'secondary'}
                style={styles.arrowDown}
                fontSize="large"
              />
            </>
          )}
        </div>
      </Draggable>
    );
  }
}

ImageOverlay.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  initialX: PropTypes.number.isRequired,
  initialY: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onDragEnd: PropTypes.func,
};

ImageOverlay.propTypes = {
  onDragEnd() {},
};

export default ImageOverlay;
