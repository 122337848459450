import convert from 'color-convert';
import { leastCommonMultiple } from './Math';
const PARTY_COLORS_HEX = [
  '#FF6B6B',
  '#FF6BB5',
  '#FF81FF',
  '#FF81FF',
  '#D081FF',
  '#81ACFF',
  '#81FFFF',
  '#81FF81',
  '#FFD081',
  '#FF8181',
];

const MIN_PARTY_DELAY = 20;

const COLOR_REPLACE_WEIGHTS = [0.5, 0.25, 0.25];

export function recolorFramesWithTint(frames, percent, ignoreTransparentPixels = false) {
  const finalImageFrameCount = leastCommonMultiple(PARTY_COLORS_HEX.length, frames.length);
  const promises = Array.from(Array(finalImageFrameCount)).map(
    (nothing, index) =>
      new Promise((resolve) => {
        const color = PARTY_COLORS_HEX[index % PARTY_COLORS_HEX.length];
        const frame = frames[index % frames.length];
        const currentImage = frame.image;

        let partifyPromise;

        if (ignoreTransparentPixels) {
          partifyPromise = getTransparencyMaskFromImage(currentImage).then((mask) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.canvas.width = currentImage.width;
            ctx.canvas.height = currentImage.height;
            // draw mask
            ctx.drawImage(mask, 0, 0);
            // overlay mask with party color
            ctx.globalAlpha = percent / 100;
            ctx.globalCompositeOperation = 'source-atop';
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, currentImage.width, currentImage.height);
            // draw original image behind so party color overlaps it
            ctx.globalCompositeOperation = 'destination-over';
            ctx.drawImage(currentImage, 0, 0);

            return canvas;
          });
        } else {
          partifyPromise = new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.canvas.width = currentImage.width;
            ctx.canvas.height = currentImage.height;
            // Draw original image
            ctx.drawImage(currentImage, 0, 0);
            // overlay image with party color
            ctx.globalAlpha = percent / 100;
            ctx.globalCompositeOperation = 'source-atop';
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, currentImage.width, currentImage.height);
            resolve(canvas);
          });
        }
        partifyPromise.then((canvas) => {
          // Make new image and frame
          const image = new Image();
          image.onload = () =>
            resolve({
              ...frame,
              image,
              url: image.src,
              delay: Math.max(frame.delay, MIN_PARTY_DELAY),
            });
          image.src = canvas.toDataURL();
        });
      })
  );

  return Promise.all(promises);
}

/**
 *
 * @param {object} frames Frames to color replace
 * @param {array} colorToReplace Color in HSL
 * @param {int} threshold Threshold in which image pixels are compared to the given color to replace
 */
export function recolorFramesWithColorReplace(frames, colorToReplace, threshold) {
  const finalImageFrameCount = leastCommonMultiple(PARTY_COLORS_HEX.length, frames.length);
  const promises = Array.from(Array(frames.length)).map((_, index) =>
    // Make Masks
    new Promise((resolve) => {
      const frame = frames[index];
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.canvas.width = frame.image.width;
      ctx.canvas.height = frame.image.height;
      ctx.drawImage(frame.image, 0, 0);

      const pixels = ctx.getImageData(0, 0, frame.image.width, frame.image.height);
      const colorMask = getColorReplaceMask(pixels.data, colorToReplace, threshold);
      const maskPixels = new ImageData(colorMask, frame.image.width, frame.image.height);
      ctx.putImageData(maskPixels, 0, 0);
      const image = new Image();
      image.onload = () => resolve({ image, index });
      image.src = canvas.toDataURL();
    }).then(
      // Replace Color
      ({ image: mask, index: frameIndex }) => {
        const frame = frames[frameIndex];
        const originalImage = frame.image;
        const replacePromises = Array.from(Array(finalImageFrameCount / frames.length)).map(
          (_, index) =>
            new Promise((resolve) => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              ctx.canvas.width = originalImage.width;
              ctx.canvas.height = originalImage.height;
              // draw mask
              ctx.drawImage(mask, 0, 0);
              // fill mask with party color
              ctx.globalCompositeOperation = 'source-in';
              ctx.fillStyle =
                PARTY_COLORS_HEX[(frameIndex + index * frames.length) % PARTY_COLORS_HEX.length];
              ctx.fillRect(0, 0, originalImage.width, originalImage.height);
              // Draw original image behind so party color overlaps it
              ctx.globalCompositeOperation = 'destination-over';
              ctx.drawImage(originalImage, 0, 0);

              // Make new image and frame
              const image = new Image();
              image.onload = () =>
                resolve({
                  ...frame,
                  image,
                  url: image.src,
                  delay: Math.max(frame.delay, MIN_PARTY_DELAY),
                });
              image.src = canvas.toDataURL();
            })
        );

        return Promise.all(replacePromises);
      }
    )
  );

  return Promise.all(promises).then((nestedColoredFrames) => {
    // all frames should have the same number of colors, so just use the first
    return nestedColoredFrames[0].reduce((acc, _, index) => {
      acc.push(...nestedColoredFrames.map((coloredFrames) => coloredFrames[index]));
      return acc;
    }, []);
  });
}

function getColorReplaceMask(pixelData, colorToReplace, threshold) {
  const newPixelData = [];
  for (let i = 0; i < pixelData.length; i += 4) {
    if (
      pixelData[i + 3] !== 0 &&
      isSimilar(
        convert.rgb.hsl(...[pixelData[i], pixelData[i + 1], pixelData[i + 2]]),
        colorToReplace,
        threshold
      )
    ) {
      newPixelData.push(...[255, 0, 0, 255]);
    } else {
      newPixelData.push(255, 255, 255, 0);
    }
  }
  return Uint8ClampedArray.from(newPixelData);
}

function getTransparencyMaskFromImage(image) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = image.width;
    ctx.canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    const pixels = ctx.getImageData(0, 0, image.width, image.height);
    const newPixelData = [];
    for (let i = 0; i < pixels.data.length; i += 4) {
      if (pixels.data[i + 3] === 255) {
        newPixelData.push(...pixels.data.slice(i, i + 4));
      } else {
        newPixelData.push(255, 255, 255, 0);
      }
    }
    const colorMask = Uint8ClampedArray.from(newPixelData);
    const maskPixels = new ImageData(colorMask, image.width, image.height);
    ctx.putImageData(maskPixels, 0, 0);
    const mask = new Image();
    mask.onload = () => resolve(mask);
    mask.src = canvas.toDataURL();
  });
}

function isSimilar(color1, color2, threshold) {
  const weightedAverageDiff = color1.reduce(
    (acc, value, index) => acc + Math.abs(value - color2[index]) * COLOR_REPLACE_WEIGHTS[index],
    0
  );

  return threshold >= weightedAverageDiff;
}

export const getColorFromPixel = (pixel) =>
  pixel.length > 0 ? formatColor(convert.rgb.hex(...pixel)) : '';

export const formatColor = (color) => (color.charAt(0) === '#' ? color : `#${color}`);

export const isValidColor = (color) => {
  if (typeof color !== 'string') {
    return false;
  }
  // Remove #
  const hex = color.slice(1);
  // Hex is RGB, RGBA, RRGGBB, or RRGGBBAA and is a valid hex number
  return [3, 4, 6, 8].includes(hex.length) && !isNaN(Number('0x' + hex));
};
