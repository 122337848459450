import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import image from '../resources/images/SelectAnImage.gif';

const useStyles = makeStyles({
  button: {
    background: 'white',
    padding: 0,
    textTransform: 'none',
    width: '60vw',
  },
  image: {
    height: 'auto',
    width: '100%',
  },
  typographyWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
});

const PlaceholderSelect = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} variant="outlined" classes={{ root: classes.button }}>
      <Box display="flex" flexDirection="column" className={classes.container}>
        <img src={image} className={classes.image} alt="No source" tilte="No source image" />
        <div className={classes.typographyWrapper}>
          <Typography component="h5" variant="h5">
            Select an image to edit!
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Choose an uploaded image to the left to start creating
          </Typography>
        </div>
      </Box>
    </Button>
  );
};

PlaceholderSelect.propTypes = {
  onClick: PropTypes.func,
};

PlaceholderSelect.defaultProps = {
  onClick() {},
};

export default PlaceholderSelect;
