import React from 'react';
import './App.css';
import {
  Fab,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  AppBar,
  Snackbar,
} from '@material-ui/core';
import { black } from '@material-ui/core/colors/common';
import { AddAPhoto } from '@material-ui/icons';
import gifFrames from 'gif-frames';
import AnimatedCanvas from './components/AnimatedCanvas';
import FramesCanvas from './components/FramesCanvas';
import PlaceholderSource from './components/PlaceholderSource';
import PlaceholderSelect from './components/PlaceholderSelect';
import Toolbox from './components/Toolbox';
import { TOOLS } from './constants/tool_constants';
import { Alert } from '@material-ui/lab';
import { emptyFrame } from './image_utilities/Empty';

const VALID_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

const MAX_UNDO_STACK_LENGTH = 3;

const EYE_DROPPER_SOURCE = {
  NONE: 'none',
  PARTIFY: 'canvas',
  TRANSPARENT: 'transparent',
};

const appBarHeight = '32px';
const toolBarWidth = '75px';
const toolDrawerWidth = '25%';
const styles = {
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },

  contentWrapper: {
    marginLeft: '2%',
    position: 'relative',
  },

  overlayWrapper: {
    marginLeft: '2%',
    position: 'relative',
    background: black,
    opacity: 0.5,
  },

  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  progressMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },

  appBar: {
    position: 'relative',
    height: appBarHeight,
  },

  toolBar: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  toolBarWrapper: {
    top: appBarHeight,
    width: toolBarWidth,
  },

  openToolDrawer: {
    top: appBarHeight,
    width: toolDrawerWidth,
    left: toolBarWidth,
  },

  closedToolDrawer: {
    width: '0%',
  },

  mainContent: {
    marginLeft: toolBarWidth,
    backgroundColor: '#F0FFF0',
  },

  mainContentShift: {
    marginLeft: 'calc(' + toolBarWidth + ' + ' + toolDrawerWidth + ')',
    backgroundColor: '#F0FFF0',
  },

  drawerContentWrapper: {
    padding: '10px',
  },
};

class App extends React.Component {
  state = {
    images: [],
    imageUndoStack: [],
    imageRedoStack: [],
    activeTool: TOOLS.NONE,
    isDrawerOpen: false,
    errorMessage: '',
    imagesAreLoading: false,
    hoverPixel: new Uint8ClampedArray(),
    selectedPixel: new Uint8ClampedArray(),
    eyeDropperIsEnabled: false,
    selectedImagesNames: {},
    selectedFrameIndices: [],
    canvasDimensions: { width: 450, height: 450 },
    canvasBackgroundColor: '#ffffff',
    currentTransparentColor: '',
    canShowSaveDialog: true,
    eyeDropperSource: EYE_DROPPER_SOURCE.NONE,
    canPlay: true,
    workingFrameOrder: [],
    workingDeletedFrameIndices: [],
    workingEmptiedFrameIndices: [],
    previewImage: null,
    isImageSelectOpen: false,
  };

  fileUpload = React.createRef();

  handleFileChange = (e) => {
    this.setState({ imagesAreLoading: true });
    const file = this.fileUpload.current.files[0];
    if (!file) {
      this.setState({ imagesAreLoading: false });
      return;
    }
    if (!this.validateFileIsImage(file)) {
      this.setState({
        imagesAreLoading: false,
        errorMessage:
          'File extension not recognized.  Must be one of: ' + VALID_FILE_EXTENSIONS.join(', '),
      });
      return;
    }
    // Clear html input field
    e.target.value = null;
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const imageSrc = [reader.result];
      // Load as gif
      if (file.name.split('.').pop() === 'gif') {
        gifFrames({
          url: imageSrc,
          frames: 'all',
          outputType: 'canvas',
          cumulative: false,
        })
          .then((frameData) => {
            const promises = frameData.map(
              (frameDatum) =>
                new Promise((resolve, reject) => {
                  const canvas = frameDatum.getImage();
                  const image = new Image();
                  image.onload = () =>
                    resolve({
                      image,
                      url: image.src,
                      delay: frameDatum.frameInfo.delay * 10, // Convert from 1/100th to 1/1000th
                      width: frameDatum.frameInfo.width,
                      height: frameDatum.frameInfo.height,
                      x: 0, // Relative to image position
                      y: 0,
                    });
                  image.onerror = () => reject(frameDatum);
                  image.src = canvas.toDataURL();
                })
            );
            Promise.all(promises).then((frames) => this.addImage(file.name, frames));
          })
          .catch((error) => {
            this.setState({ errorMessage: 'Could not split gif into frames.' });
          });
      } else {
        // TODO promise all this stuff
        const image = new Image();
        image.onload = () => {
          const frames = [
            {
              image,
              url: image.src,
              delay: 0,
              width: image.width,
              height: image.height,
              x: 0,
              y: 0,
            },
          ];
          this.addImage(file.name, frames);
        };
        image.src = imageSrc;
      }
    };
    reader.readAsDataURL(file);
  };

  addImage = (fileName, frames) => {
    this.setState((prevState) => {
      // Ensure we don't have name duplicates
      const imagesByName = prevState.images.reduce((acc, image) => {
        acc[image.name] = image.name;
        return acc;
      }, {});
      const splitFileName = fileName.split('.');
      const extension = splitFileName.pop();
      const oldName = splitFileName.shift();
      let name = fileName;
      Object.keys(imagesByName).some((unused, index) => {
        if (name in imagesByName) {
          name = `${oldName} (${index + 1}).${extension}`;
          return false;
        }
        return true;
      });
      const newState = {
        imagesAreLoading: false,
        images: prevState.images.concat({
          frames,
          x: 0,
          y: 0,
          name,
          startIndex: 0,
        }),
      };

      if (this.state.images.length === 0) {
        newState.canvasDimensions = { width: frames[0].width, height: frames[0].height };
      }

      return newState;
    });
  };

  handleToolChange = (newTool) => {
    this.setState((prevState) => ({
      activeTool: prevState.activeTool === newTool ? TOOLS.NONE : newTool,
      isDrawerOpen: prevState.activeTool !== newTool,
    }));
  };

  handleDrawerClose = () => {
    this.setState({
      isDrawerOpen: false,
      activeTool: TOOLS.NONE,
    });
  };

  clearAllImages = () => {
    this.setState({
      images: [],
      errorMessage: '',
    });
  };

  validateFileIsImage = (file) => {
    const fileExtension = file.name.split('.').pop();
    if (!VALID_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
      return false;
    }
    return true;
  };

  handlePlaceholderSourceClick = () => {
    this.fileUpload.current.click();
  };

  handlePlaceholderSelectClick = () => {
    this.setState({ isImageSelectOpen: true });
  };

  handleImageSelectToggle = () => {
    this.setState((prevState) => ({
      isImageSelectOpen: !prevState.isImageSelectOpen,
    }));
  };

  handleEyeDropperClick = () => {
    this.setState((prevState) => ({
      eyeDropperIsEnabled: !prevState.eyeDropperIsEnabled,
      eyeDropperSource: EYE_DROPPER_SOURCE.PARTIFY,
      canPlay: prevState.eyeDropperIsEnabled,
      selectedPixel: new Uint8ClampedArray(),
      hoverPixel: new Uint8ClampedArray(),
    }));
  };

  handleSaveEyeDropperClick = () => {
    this.setState({
      eyeDropperIsEnabled: true,
      eyeDropperSource: EYE_DROPPER_SOURCE.TRANSPARENT,
      canPlay: false,
      selectedPixel: new Uint8ClampedArray(),
      hoverPixel: new Uint8ClampedArray(),
      canShowSaveDialog: false,
    });
  };

  handleImageChange = (images) => {
    this.setState((prevState) => {
      return {
        images: prevState.images.reduce((acc, oldImage) => {
          const newImage = images.find((image) => image.name === oldImage.name);
          acc.push(newImage !== undefined ? newImage : oldImage);
          return acc;
        }, []),
        imageUndoStack: [
          prevState.images,
          ...prevState.imageUndoStack.slice(0, MAX_UNDO_STACK_LENGTH - 1),
        ],
        imageRedoStack: [],
        imagesAreLoading: false,
        workingDeletedFrameIndices: [],
        workingEmptiedFrameIndices: [],
        workingFrameOrder: [],
        selectedFrameIndices: [],
      };
    });
  };

  handleImageReorder = (images) => {
    this.setState((prevState) => ({
      images: images,
      imageUndoStack: [
        prevState.images,
        ...prevState.imageUndoStack.slice(0, MAX_UNDO_STACK_LENGTH - 1),
      ],
      imageRedoStack: [],
      imagesAreLoading: false,
    }));
  };

  handleImageRemoval = (imagesToRemove) => {
    this.setState((prevState) => ({
      images: prevState.images.reduce((acc, oldImage) => {
        const imageToRemove = imagesToRemove.find((image) => image.name === oldImage.name);
        if (imageToRemove === undefined) {
          acc.push(oldImage);
        }
        return acc;
      }, []),
      imagesAreLoading: false,
    }));
  };

  handleImageLoadStart = () => {
    this.setState({ imagesAreLoading: true });
  };

  handleImageMouseMove = (e, pixel) => {
    if (pixel.length > 0) {
      this.setState({ hoverPixel: pixel });
    }
  };

  handleImageClick = (e) => {
    this.setState((prevState) => ({
      selectedPixel: prevState.hoverPixel,
      eyeDropperIsEnabled: false,
      canPlay: true,
      canShowSaveDialog:
        prevState.eyeDropperSource === EYE_DROPPER_SOURCE.TRANSPARENT
          ? true
          : prevState.canShowSaveDialog,
    }));
  };

  handleChangeStartIndex = (index, delta) => {
    this.setState((prevState) => ({
      images: [
        ...prevState.images.slice(0, index),
        {
          ...prevState.images[index],
          startIndex: prevState.images[index].startIndex + delta,
        },
        ...prevState.images.slice(index + 1),
      ],
    }));
  };

  handleUndoClick = () => {
    if (this.state.imageUndoStack.length === 0) {
      return;
    }
    this.setState((prevState) => {
      const [images, ...imageUndoStack] = prevState.imageUndoStack;
      return {
        images,
        imageUndoStack,
        imageRedoStack: [prevState.images, ...prevState.imageRedoStack],
      };
    });
  };

  handleRedoClick = () => {
    if (this.state.imageRedoStack.length === 0) {
      return;
    }
    this.setState((prevState) => {
      const [images, ...imageRedoStack] = prevState.imageRedoStack;
      return {
        images,
        imageRedoStack,
        imageUndoStack: [
          prevState.images,
          ...prevState.imageUndoStack.slice(0, MAX_UNDO_STACK_LENGTH - 1),
        ],
      };
    });
  };

  getSelectedOrHoverPixel = () => {
    if (this.state.selectedPixel.length > 0) {
      return this.state.selectedPixel;
    } else if (this.state.eyeDropperIsEnabled) {
      return this.state.hoverPixel;
    }
    return new Uint8ClampedArray();
  };

  handleImageCheckboxChange = (name) => {
    this.setState((prevState) => {
      if (name in prevState.selectedImagesNames) {
        // Delete image with name from check images list
        const {
          [name]: nowUncheckedImageName,
          ...selectedImagesNames
        } = prevState.selectedImagesNames;
        return { selectedImagesNames, selectedFrameIndices: [] };
      }
      // Add image name to list
      return {
        selectedImagesNames: { ...prevState.selectedImagesNames, [name]: true },
        selectedFrameIndices: [],
      };
    });
  };

  getSelectedImages() {
    return this.state.images.reduce((selectedImages, image) => {
      if (image.name in this.state.selectedImagesNames) {
        return selectedImages.concat(image);
      }
      return selectedImages;
    }, []);
  }

  handleCanvasChange = (width, height, canvasBackgroundColor) => {
    this.setState({ canvasDimensions: { width, height }, canvasBackgroundColor });
  };

  handleCloseError = () => {
    this.setState({ errorMessage: '' });
  };

  handleFrameClick = (imageName, frameIndex) => {
    this.setState({
      selectedImagesNames: { [imageName]: true },
      selectedFrameIndices: [frameIndex],
      activeTool: TOOLS.FRAMES,
      isDrawerOpen: true,
    });
  };

  handleImageSelect = (name) =>
    this.setState({
      selectedImagesNames: name !== '' ? { [name]: true } : {},
      selectedFrameIndices: [],
      previewImage: null,
    });

  handleSelectedFrameChange = (selectedFrameIndex) => {
    this.setState((prevState) => ({
      selectedFrameIndices: this.addOrRemoveIfExists(
        selectedFrameIndex,
        prevState.selectedFrameIndices
      ),
    }));
  };

  handleWorkingFrameOrderChange = (newOrder) => {
    this.setState({ workingFrameOrder: newOrder });
  };

  handleWorkingDeletedFrameIndicesChange = (deletedFrameIndex) => {
    this.setState((prevState) => ({
      workingDeletedFrameIndices: this.addOrRemoveIfExists(
        deletedFrameIndex,
        prevState.workingDeletedFrameIndices
      ),
      selectedFrameIndices: this.removeIfExists(deletedFrameIndex, prevState.selectedFrameIndices),
    }));
  };

  handleWorkingEmptiedFrameIndicesChange = (emptiedFrameIndex) => {
    this.setState((prevState) => ({
      workingEmptiedFrameIndices: this.addOrRemoveIfExists(
        emptiedFrameIndex,
        prevState.workingEmptiedFrameIndices
      ),
    }));
  };

  removeIfExists = (x, arr) => {
    const index = arr.indexOf(x);
    const newArr = [...arr];
    if (index !== -1) {
      newArr.splice(index, 1);
    }
    return newArr;
  };

  addOrRemoveIfExists = (x, arr) => {
    const index = arr.indexOf(x);
    let newArr = [...arr];
    index === -1 ? newArr.push(x) : newArr.splice(index, 1);
    return newArr;
  };

  getFrameOrderWithoutDeleted = () => {
    if (this.getSelectedImages().length === 0) {
      return [];
    }

    let frameOrder = this.state.workingFrameOrder;
    if (frameOrder.length === 0) {
      frameOrder = this.getSelectedImages()[0].frames.map((_, index) => index);
    }
    return frameOrder.filter((x) => !this.state.workingDeletedFrameIndices.includes(x));
  };

  handleFramesChange = (newDelay, newX, newY) => {
    this.handleImageLoadStart();
    this.updateSelectedImageFrames(newDelay, newX, newY).then((newImage) => {
      this.handleImageChange([newImage]);
    });
  };

  updateSelectedImageFrames = (newDelay, newX, newY) => {
    const firstSeletedImage = this.getSelectedImages()[0];
    const promises = [];
    const updateFrameDataCallback = (frame, index) => {
      const newFrame = { ...frame };
      if (this.state.selectedFrameIndices.includes(index)) {
        newFrame.delay = newDelay;
        newFrame.x = newX;
        newFrame.y = newY;
      }
      return newFrame;
    };
    firstSeletedImage.frames.forEach((frame, index) => {
      if (this.state.workingEmptiedFrameIndices.includes(index)) {
        promises.push(
          emptyFrame(frame).then((emptyFrame) => updateFrameDataCallback(emptyFrame, index))
        );
      } else {
        promises.push(new Promise((resolve) => resolve(updateFrameDataCallback(frame, index))));
      }
    });

    return Promise.all(promises).then((newFrames) => {
      const reordredNewFrames = this.getFrameOrderWithoutDeleted().map((index) => {
        return newFrames[index];
      });
      return { ...firstSeletedImage, frames: reordredNewFrames };
    });
  };

  handleFramesPreview = (newDelay, newX, newY) => {
    this.handleImageLoadStart();
    this.updateSelectedImageFrames(newDelay, newX, newY).then((newImage) => {
      this.setState({
        previewImage: newImage,
        imagesAreLoading: false,
      });
    });
  };

  handleFramesPreviewExit = () => {
    this.setState({ previewImage: null });
  };

  render() {
    const selectedImages = this.getSelectedImages();
    return (
      <div className="App">
        <AppBar position="static">
          <Typography variant="h6">Peamoji</Typography>
        </AppBar>
        <Snackbar open={this.state.errorMessage !== ''} onClose={this.handleCloseError}>
          <Alert onClose={this.handleCloseError} variant="filled" severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Card square style={this.state.isDrawerOpen ? styles.mainContentShift : styles.mainContent}>
          <CardContent>
            <div
              style={this.state.imagesAreLoading ? styles.overlayWrapper : styles.contentWrapper}
            >
              {(() => {
                if (this.state.images.length > 0 && this.state.activeTool !== TOOLS.FRAMES) {
                  return (
                    <AnimatedCanvas
                      images={this.state.images}
                      onMouseMove={this.handleImageMouseMove}
                      onClick={this.handleImageClick}
                      canPlay={this.state.canPlay}
                      canMoveImages={!this.state.eyeDropperIsEnabled}
                      onClickPlay={this.handleClickPlay}
                      onStartIndexChange={this.handleChangeStartIndex}
                      width={this.state.canvasDimensions.width}
                      height={this.state.canvasDimensions.height}
                      backgroundColor={this.state.canvasBackgroundColor}
                      onImageChange={this.handleImageChange}
                      onImageChangeStart={this.handleImageLoadStart}
                      onUndoClick={this.handleUndoClick}
                      onRedoClick={this.handleRedoClick}
                      onEyeDropperClick={this.handleSaveEyeDropperClick}
                      canUndo={this.state.imageUndoStack.length > 0}
                      canRedo={this.state.imageRedoStack.length > 0}
                      canShowSaveDialog={this.state.canShowSaveDialog}
                      selectedPixel={
                        this.state.eyeDropperSource === EYE_DROPPER_SOURCE.TRANSPARENT
                          ? this.state.selectedPixel
                          : new Uint8ClampedArray()
                      }
                      showEyeDropperCursor={this.state.eyeDropperIsEnabled}
                      onFrameClick={this.handleFrameClick}
                    />
                  );
                } else if (this.state.activeTool === TOOLS.FRAMES && this.state.images.length > 0) {
                  return selectedImages.length > 0 ? (
                    <FramesCanvas
                      image={selectedImages[0]}
                      selectedFrameIndices={this.state.selectedFrameIndices}
                      mainCanvasWidth={this.state.canvasDimensions.width}
                      mainCanvasHeight={this.state.canvasDimensions.height}
                      backgroundColor={this.state.canvasBackgroundColor}
                      frameOrder={this.state.workingFrameOrder}
                      framesMarkedForDeletion={this.state.workingDeletedFrameIndices}
                      framesMarkedForEmptying={this.state.workingEmptiedFrameIndices}
                      onFrameClick={this.handleSelectedFrameChange}
                      onFrameDelete={this.handleWorkingDeletedFrameIndicesChange}
                      onFrameEmpty={this.handleWorkingEmptiedFrameIndicesChange}
                      onOrderChange={this.handleWorkingFrameOrderChange}
                      previewImage={this.state.previewImage}
                    />
                  ) : (
                    <PlaceholderSelect onClick={this.handlePlaceholderSelectClick} />
                  );
                } else {
                  return <PlaceholderSource onClick={this.handlePlaceholderSourceClick} />;
                }
              })()}
              {this.state.imagesAreLoading && (
                <CircularProgress disableShrink size={100} style={styles.progress} />
              )}
            </div>
          </CardContent>
          <input
            accept="image/*"
            id="button-file"
            multiple
            ref={this.fileUpload}
            type="file"
            onChange={this.handleFileChange}
            hidden
          />
          <Fab
            style={styles.fab}
            color="primary"
            aria-label="add image"
            onClick={this.handlePlaceholderSourceClick}
          >
            <AddAPhoto />
          </Fab>
        </Card>
        <Toolbox
          images={this.state.images}
          selectedImages={selectedImages}
          selectedFrameIndices={this.state.selectedFrameIndices}
          // Don't display frames that are deleted
          firstSelectedImageFrameOrder={this.getFrameOrderWithoutDeleted()}
          isOpen={this.state.isDrawerOpen}
          onDrawerClose={this.handleDrawerClose}
          activeTool={this.state.activeTool}
          onToolChange={this.handleToolChange}
          onImageCheckboxChange={this.handleImageCheckboxChange}
          onImageSelect={this.handleImageSelect}
          selectedImagesNames={this.state.selectedImagesNames}
          onImageChange={this.handleImageChange}
          onImageChangeStart={this.handleImageLoadStart}
          onImageRemoval={this.handleImageRemoval}
          onImageReorder={this.handleImageReorder}
          selectedPixel={
            this.state.eyeDropperSource === EYE_DROPPER_SOURCE.PARTIFY
              ? this.getSelectedOrHoverPixel()
              : new Uint8ClampedArray()
          }
          eyeDropperIsEnabled={this.state.eyeDropperIsEnabled}
          onEyeDropperClick={this.handleEyeDropperClick}
          onCanvasChange={this.handleCanvasChange}
          canvasDimensions={this.state.canvasDimensions}
          canvasBackgroundColor={this.state.canvasBackgroundColor}
          onFramesChange={this.handleFramesChange}
          onFramesPreviewClick={this.handleFramesPreview}
          onFramesPreviewBackClick={this.handleFramesPreviewExit}
          framesIsPreviewing={this.state.previewImage !== null}
          onImageSelectToggle={this.handleImageSelectToggle}
          isImageSelectOpen={this.state.isImageSelectOpen}
        />
      </div>
    );
  }
}

export default App;
