export const emptyFrame = (frame) => {
  return getEmptyImage(frame.width, frame.height).then((emptyImage) => {
    return {
      ...frame,
      image: emptyImage,
      url: emptyImage.src,
      delay: '',
      isEmpty: true,
    };
  });
};

export const getEmptyImage = (width, height) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = width;
    ctx.canvas.height = height;
    const emptyImage = new Image();
    emptyImage.onload = () => {
      resolve(emptyImage);
    };
    emptyImage.src = canvas.toDataURL();
  });
};
