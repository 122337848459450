import PropTypes from 'prop-types';

export const frameShape = PropTypes.shape({
  image: PropTypes.object,
  url: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const imageShape = PropTypes.shape({
  frames: PropTypes.arrayOf(frameShape),
  x: PropTypes.number,
  y: PropTypes.number,
  name: PropTypes.string,
  startIndex: PropTypes.number,
});
