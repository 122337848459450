import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider } from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import { resizeAllFrames } from '../image_utilities/Resize';
import ImageForm from './ImageForm';

class ImagesTransform extends React.Component {
  findImageIndex = name => {
    return this.props.images.findIndex(image => image.name === name);
  };

  handleSubmit = (name, formData) => {
    this.props.onImageChangeStart();
    this.handlePositionInputSubmit(
      this.props.images[this.findImageIndex(name)],
      formData.x,
      formData.y
    )
      .then(modifiedPositionImage =>
        this.handleSizeInputSubmit(modifiedPositionImage, formData.width, formData.height)
      )
      .then(modifiedImage => this.props.onImageChange([modifiedImage]))
      .catch(() => {
        // noop to stop loading
        this.props.onImageChange(this.props.images);
      });
  };

  handleDelete = name => {
    this.props.onImageChangeStart();
    this.props.onImageRemoval([this.props.images[this.findImageIndex(name)]]);
  };

  handleSizeInputSubmit = (image, width, height) => {
    // Only resize if there's actually a reason to. Otherwise we'll just lower image quality for no reason
    if (image.frames[0].width !== width || image.frames[0].height !== height) {
      return resizeAllFrames(image.frames, width, height).then(resizedFrames => {
        return {
          ...image,
          frames: resizedFrames,
        };
      });
    } else {
      // Nothing to change, perform noop
      return new Promise(resolve => resolve(image));
    }
  };

  handlePositionInputSubmit = (image, x, y) => {
    return new Promise(resolve =>
      resolve({
        ...image,
        x,
        y,
      })
    );
  };

  render() {
    return this.props.images.length ? (
      this.props.images.map((image, index) => (
        <React.Fragment key={image.name + index}>
          <ImageForm image={image} onSubmit={this.handleSubmit} onDeleteClick={this.handleDelete} />
          <Divider />
        </React.Fragment>
      ))
    ) : (
      <Typography>
        You will be able to manipulate image position and size here, but first you have to add one!
      </Typography>
    );
  }
}

ImagesTransform.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  onImageChange: PropTypes.func,
  onImageChangeStart: PropTypes.func,
  onImageRemoval: PropTypes.func,
};
ImagesTransform.defaultProps = {
  onImageChange() {},
  onImageChangeStart() {},
  onImageRemoval() {},
  checkedImagesNames: {},
};

export default ImagesTransform;
