import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  DialogActions,
  TextField,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import ColorTextField from './UI/ColorTextField';

const SaveDialog = ({
  onTransparentColorChange,
  onNameChange,
  onEyeDropperClick,
  currentTransparentColor,
  onClickSave,
  onClose,
  isOpen,
  isLoading,
}) => {
  const [inputIsValid, setInputIsValid] = useState({ transparentColor: true });
  const canSubmit = Object.values(inputIsValid).every(Boolean);
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Save Options</DialogTitle>
      <DialogContent>
        <ColorTextField
          onColorChange={(color, isValid) => {
            setInputIsValid({ ...inputIsValid, transparentColor: isValid });
            onTransparentColorChange(color, isValid);
          }}
          label="Transparent Color"
          value={currentTransparentColor !== '' ? currentTransparentColor : ''}
          onEyeDropperClick={e => {
            setInputIsValid({ ...inputIsValid, transparentColor: true });
            onEyeDropperClick(e);
          }}
          showEyeDropper
          allowEmpty
        />
        <Box display="flex" alignItems="flex-end">
          <TextField onChange={onNameChange} label="Image Name" />
          <Typography>.gif</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClickSave}
          disabled={isLoading || !canSubmit}
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
      {isLoading && <LinearProgress />}
    </Dialog>
  );
};

SaveDialog.propTypes = {
  onTransparentColorChange: PropTypes.func,
  onNameChange: PropTypes.func,
  onClickSave: PropTypes.func,
  onClose: PropTypes.func,
  onEyeDropperClick: PropTypes.func,
  currentTransparentColor: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SaveDialog.defaultProps = {
  onTransparentColorChange() {},
  onNameChange() {},
  onClickSave() {},
  onClose() {},
  onEyeDropperClick() {},
  currentTransparentColor: '',
  isOpen: false,
  isLoading: false,
};

export default SaveDialog;
