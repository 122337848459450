import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, Image, Layers as LayersIcon, ViewModule } from '@material-ui/icons';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Snackbar,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { imageShape } from '../shapes/ImageShapes';
import ImageCheckList from './ImageCheckList';
import ImagesTransform from './ImagesTransform';
import Layers from './Layers';
import Partify from './Partify';
import Intensify from './Intensify';
import Slide from './Slide';
import Append from './Append';
import CanvasModifier from './CanvasModifier';
import Emoji from './UI/Emoji';
import { TOOLS, toolData } from '../constants/tool_constants';
import { makeStyles } from '@material-ui/core/styles';
import Frames from './Frames';

const useStyles = makeStyles({
  toolDrawerPaper: {
    // Below app bar
    top: '32px',
    width: '25%',
    // Ensure bottom above page bottom after app bar shift
    height: 'calc(100% - 32px)',
    // Beside tool bar
    left: '75px',
  },
  toolBarBottomNavigationRoot: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  toolBarPaper: {
    // Below app bar
    top: '32px',
    width: '75px',
  },
  toolDrawerContentWrapper: {
    padding: '10px',
  },
  imageSelectRoot: {
    textAlign: 'left',
  },
});

const Toolbox = ({
  images,
  selectedImages,
  isOpen,
  onDrawerClose,
  activeTool,
  onToolChange,
  onImageCheckboxChange,
  onImageSelect,
  selectedImagesNames,
  selectedFrameIndices,
  onImageChange,
  onImageChangeStart,
  onImageRemoval,
  onImageReorder,
  selectedPixel,
  eyeDropperIsEnabled,
  onEyeDropperClick,
  onCanvasChange,
  canvasDimensions,
  canvasBackgroundColor,
  onFramesChange,
  firstSelectedImageFrameOrder,
  onFramesPreviewClick,
  onFramesPreviewBackClick,
  framesIsPreviewing,
  onImageSelectToggle,
  isImageSelectOpen,
}) => {
  const classes = useStyles();
  // We use a specific isOpen flag for the error pop-up to avoid showing a blank error
  // for half a second before closing
  const [error, setError] = useState('');
  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const handleCloseError = () => {
    setErrorIsOpen(false);
  };
  const handleError = (message) => {
    setError(message);
    setErrorIsOpen(true);
  };

  return (
    <>
      <Drawer
        anchor="left"
        variant="persistent"
        open={isOpen}
        onClose={onDrawerClose}
        classes={isOpen ? { paper: classes.toolDrawerPaper } : {}}
        transitionDuration={0}
      >
        <div className={classes.toolDrawerContentWrapper}>
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">{toolData[activeTool].title}</Typography>
              <IconButton onClick={onDrawerClose}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Divider />
            <Box marginBottom="10px" />
            {toolData[activeTool].showImageList && (
              <>
                {toolData[activeTool].canSelectMultiple ? (
                  <ImageCheckList
                    images={images}
                    onCheckboxChange={onImageCheckboxChange}
                    checkedImagesNames={selectedImagesNames}
                  />
                ) : (
                  <Box display="flex">
                    <Box marginRight="10px">
                      <Image color="action" />
                    </Box>
                    <Box width="100%">
                      <Select
                        classes={{ root: classes.imageSelectRoot }}
                        value={selectedImages[0]?.name ?? ''}
                        onChange={(e) => onImageSelect(e.target.value)}
                        fullWidth
                        native={false}
                        onOpen={onImageSelectToggle}
                        onClose={onImageSelectToggle}
                        open={isImageSelectOpen}
                      >
                        <MenuItem value="">None</MenuItem>
                        {images.map((image) => (
                          <MenuItem key={image.name} value={image.name}>
                            {image.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                )}
                <Box marginBottom="10px" />
              </>
            )}
            <Snackbar open={errorIsOpen} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} variant="filled" severity="error">
                {error}
              </Alert>
            </Snackbar>
            {(() => {
              switch (activeTool) {
                case TOOLS.IMAGES:
                  return (
                    <ImagesTransform
                      images={images}
                      onImageChange={onImageChange}
                      onImageRemoval={onImageRemoval}
                      onImageChangeStart={onImageChangeStart}
                    />
                  );
                case TOOLS.LAYERS:
                  return (
                    <Layers
                      images={images}
                      onImageChange={onImageReorder}
                      onImageChangeStart={onImageChangeStart}
                    />
                  );
                case TOOLS.FRAMES:
                  return (
                    <Frames
                      image={selectedImages[0] ?? {}}
                      selectedFrameIndices={selectedFrameIndices}
                      frameOrder={firstSelectedImageFrameOrder}
                      onSubmit={onFramesChange}
                      onPreviewClick={onFramesPreviewClick}
                      onPreviewBackClick={onFramesPreviewBackClick}
                      isPreviewing={framesIsPreviewing}
                    />
                  );
                case TOOLS.PARTIFY:
                  return (
                    <Partify
                      images={selectedImages}
                      onImageChange={onImageChange}
                      onImageChangeStart={onImageChangeStart}
                      onEyeDropperClick={onEyeDropperClick}
                      selectedPixel={selectedPixel}
                      eyeDropperIsEnabled={eyeDropperIsEnabled}
                    />
                  );
                case TOOLS.INTENSIFY:
                  return (
                    <Intensify
                      images={selectedImages}
                      onImageChange={onImageChange}
                      onImageChangeStart={onImageChangeStart}
                    />
                  );
                case TOOLS.SLIDE:
                  return (
                    <Slide
                      images={selectedImages}
                      onImageChange={onImageChange}
                      onImageChangeStart={onImageChangeStart}
                      onError={handleError}
                    />
                  );
                case TOOLS.CANVAS:
                  return (
                    <CanvasModifier
                      onChange={onCanvasChange}
                      width={canvasDimensions.width}
                      height={canvasDimensions.height}
                      backgroundColor={canvasBackgroundColor}
                    />
                  );
                case TOOLS.APPEND:
                  return (
                    <Append
                      images={selectedImages}
                      onImageChange={onImageChange}
                      onImageChangeStart={onImageChangeStart}
                    />
                  );
                case TOOLS.NONE:
                default:
                  return null;
              }
            })()}
          </>
        </div>
      </Drawer>
      <Drawer variant="permanent" open anchor="left" classes={{ paper: classes.toolBarPaper }}>
        <BottomNavigation
          showLabels
          value={activeTool}
          onChange={(e, value) => {
            onToolChange(value);
          }}
          classes={{ root: classes.toolBarBottomNavigationRoot }}
        >
          <BottomNavigationAction label="Images" icon={<Image />} value={TOOLS.IMAGES} />
          <BottomNavigationAction label="Layers" icon={<LayersIcon />} value={TOOLS.LAYERS} />
          <BottomNavigationAction label="Frames" icon={<ViewModule />} value={TOOLS.FRAMES} />
          <BottomNavigationAction
            label="Partify"
            icon={<Emoji symbol="🎉" label="party" />}
            value={TOOLS.PARTIFY}
          />
          <BottomNavigationAction
            label="Intensify"
            icon={<Emoji symbol="↔️" label="shake" />}
            value={TOOLS.INTENSIFY}
          />
          <BottomNavigationAction
            label="Slide"
            icon={<Emoji symbol="↩️" label="slide" />}
            value={TOOLS.SLIDE}
          />
          <BottomNavigationAction
            label="Append"
            icon={<Emoji symbol="➕" label="append" />}
            value={TOOLS.APPEND}
          />
          <BottomNavigationAction
            label="Canvas"
            icon={<Emoji symbol="⬜" label="canvas" />}
            value={TOOLS.CANVAS}
          />
        </BottomNavigation>
      </Drawer>
    </>
  );
};

Toolbox.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  selectedImages: PropTypes.arrayOf(imageShape),
  isOpen: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func,
  activeTool: PropTypes.string,
  onToolChange: PropTypes.func,
  onImageCheckboxChange: PropTypes.func,
  onImageSelect: PropTypes.func,
  selectedImagesNames: PropTypes.object,
  selectedFrameIndices: PropTypes.arrayOf(PropTypes.number),
  onImageChange: PropTypes.func,
  onImageChangeStart: PropTypes.func,
  onImageRemoval: PropTypes.func,
  onImageReorder: PropTypes.func,
  selectedPixel: PropTypes.objectOf(PropTypes.number),
  eyeDropperIsEnabled: PropTypes.bool,
  onEyeDropperClick: PropTypes.func,
  onCanvasChange: PropTypes.func,
  canvasDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  canvasBackgroundColor: PropTypes.string,
  onFramesChange: PropTypes.func,
  onFramesPreviewClick: PropTypes.func,
  onFramesPreviewBackClick: PropTypes.func,
  framesIsPreviewing: PropTypes.bool,
  onImageSelectToggle: PropTypes.func,
  isImageSelectOpen: PropTypes.bool,
};

Toolbox.defaultProps = {
  selectedImages: [],
  onDrawerClose() {},
  activeTool: TOOLS.NONE,
  onToolChange() {},
  onImageCheckboxChange() {},
  onImageSelect() {},
  selectedImagesNames: {},
  selectedFrameIndices: [],
  onImageChange() {},
  onImageChangeStart() {},
  onImageRemoval() {},
  onImageReorder() {},
  selectedPixel: [],
  eyeDropperIsEnabled: false,
  onEyeDropperClick() {},
  onCanvasChange() {},
  canvasDimensions: {},
  canvasBackgroundColor: '',
  onFramesChange() {},
  onFramesPreviewClick() {},
  onFramesPreviewBackClick() {},
  framesIsPreviewing: false,
  onImageSelectToggle() {},
  isImageSelectOpen: false,
};

export default Toolbox;
