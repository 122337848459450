import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { randomTranslation } from '../image_utilities/Translate';
import { imageShape } from '../shapes/ImageShapes';
import NumberField from './UI/NumberField';

class Intensify extends React.Component {
  state = {
    numOutputFrames: 3,
    intensity: 10,
    delay: 40,
    inputIsValid: {
      numFrames: true,
      intensity: true,
      delay: true,
    },
  };

  handleIntensifyClick = () => {
    this.props.onImageChangeStart();
    const promises = this.props.images.map(
      image =>
        new Promise(resolve => {
          randomTranslation(
            image.frames,
            this.state.intensity,
            this.state.delay,
            this.shouldShowOutputFramesInput() ? this.state.numOutputFrames : null
          ).then(frames => {
            resolve({
              ...image,
              frames,
            });
          });
        })
    );
    Promise.all(promises).then(modifiedImages => {
      this.props.onImageChange(modifiedImages);
    });
  };

  shouldShowOutputFramesInput = () => {
    return this.props.images.some(image => image.frames.length === 1);
  };

  handleNumOutputFramesChange = (numOutputFrames, isValid) => {
    this.setState(prevState => ({
      numOutputFrames,
      inputIsValid: { ...prevState.inputIsValid, numOutputFrames: isValid },
    }));
  };

  handleIntensityChange = (intensity, isValid) => {
    this.setState(prevState => ({
      intensity,
      inputIsValid: { ...prevState.inputIsValid, intensity: isValid },
    }));
  };

  handleDelayChange = (delay, isValid) => {
    this.setState(prevState => ({
      delay,
      inputIsValid: { ...prevState.inputIsValid, delay: isValid },
    }));
  };

  canSubmit = () => Object.values(this.state.inputIsValid).every(Boolean);

  render() {
    return this.props.images.length ? (
      <Grid container spacing={1} direction="column" justify="flex-end" alignItems="flex-start">
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            {this.shouldShowOutputFramesInput() && (
              <Grid item>
                <NumberField
                  label="Number of Output Frames"
                  onChange={this.handleNumOutputFramesChange}
                  value={this.state.numOutputFrames}
                  allowPositive
                />
              </Grid>
            )}
            <Grid item>
              <NumberField
                label="Intensity"
                onChange={this.handleIntensityChange}
                value={this.state.intensity}
                allowPositive
              />
            </Grid>
            <Grid item>
              <NumberField
                label="Wait Between Frames"
                suffix="ms"
                onChange={this.handleDelayChange}
                value={this.state.delay}
                allowPositive
                allowZero
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleIntensifyClick}
            disabled={!this.canSubmit()}
          >
            Intensify
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Typography>Here you make an image shake, but first you need to add one!</Typography>
    );
  }
}

Intensify.propTypes = {
  images: PropTypes.arrayOf(imageShape),
  onImageChange: PropTypes.func.isRequired,
  onImageChangeStart: PropTypes.func,
};
Intensify.defaultProps = {
  images: [],
  onImageChange() {},
  onImageChangeStart() {},
};

export default Intensify;
