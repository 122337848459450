import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';

const ToolTippedIconButton = ({ iconButtonProps, tooltipProps, disabled, children }) => {
  return disabled ? (
    <IconButton {...iconButtonProps} disabled={disabled}>
      {children}
    </IconButton>
  ) : (
    <Tooltip {...tooltipProps}>
      <IconButton {...iconButtonProps} disabled={disabled}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

ToolTippedIconButton.propTypes = {
  iconButtonProps: PropTypes.shape({ ...IconButton.propTypes }),
  tooltipProps: PropTypes.shape({ ...Tooltip.propTypes }),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ToolTippedIconButton.defaultProps = {
  iconButtonProps: {},
  tooltipProps: {},
  disabled: false,
};

export default ToolTippedIconButton;
