import React from 'react';
import PropTypes from 'prop-types';
import { getGifBuilder } from '../image_utilities/GifBuilder';
import { imageShape } from '../shapes/ImageShapes';
import Player from './Player';
import { leastCommonMultiple } from '../image_utilities/Math';
import ImageOverlay from './ImageOverlay';
import isEqual from 'react-fast-compare';
import SaveDialog from './SaveDialog';
import { getColorFromPixel } from '../image_utilities/Recolor';

const styles = {
  canvas: {
    border: 'solid #000',
    borderWidth: '1px',
    position: 'relative',
  },
  imageOverlayWrapper: {
    position: 'absolute',
    top: '0px',
  },
};

class AnimatedCanvas extends React.Component {
  state = {
    timeout: null,
    finalGifSrc: null,
    gifBuilder: null,
    startingSaveFrame: null,
    isPlaying: false,
    absoluteCurrentIndex: 0,
    transparentColor: '',
    saveImageName: '',
  };

  componentDidMount = () => {
    this.setState(
      {
        absoluteCurrentIndex: 0,
        timeout: null,
        isPlaying: true,
      },
      () => {
        this.updateCanvas();
        if (this.isPlaying()) {
          this.requestTimeout();
        }
      }
    );
  };

  componentWillUnmount = () => {
    this.cancelTimeout();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.images.length > 0) {
      // Ability to play was changed and we're now able to
      const canNowPlay =
        (prevProps.canPlay !== this.props.canPlay ||
          prevState.isPlaying !== this.state.isPlaying) &&
        this.isPlaying();
      if (
        canNowPlay ||
        // Image was removed
        prevProps.images.length > this.props.images.length ||
        // Image was added
        prevProps.images.length < this.props.images.length ||
        // If image order changed OR the frames were modified
        !isEqual(this.props.images, prevProps.images) ||
        // Canvas was resized or color changed
        prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        prevProps.backgroundColor !== this.props.backgroundColor
      ) {
        this.cancelTimeout();
        this.setState(
          {
            absoluteCurrentIndex: 0,
            isPlaying: true,
          },
          () => {
            this.clearCanvas();
            this.updateCanvas();
            // Must be done after new gif's indices are updated so we can correctly set timeout delays
            this.requestTimeout();
          }
        );
      }
    }

    if (
      this.props.selectedPixel.length > 0 &&
      prevProps.selectedPixel !== this.props.selectedPixel
    ) {
      // If we have a new pixel, the eyedropper must have selected a color
      this.setState({ transparentColor: getColorFromPixel(this.props.selectedPixel) });
    }
  };

  cancelTimeout = () => {
    cancelAnimationFrame(this.state.timeout);
    this.setState({ timeout: null });
  };

  requestTimeout = () => {
    const start = new Date().getTime();
    // If we are trying to save, speed up the animation so we don't have to wait for the whole
    // animation to play
    const delay = this.state.startingSaveFrame === null ? this.getCurrentMaxDelay() : 1;
    if (this.state.timeout === null && this.getMaxFrames() > 1) {
      this.createTimeout(start + delay);
    }
  };

  createTimeout = (end) => {
    const timeout = requestAnimationFrame(this.getTimeoutCallback(end));
    this.setState({ timeout });
  };

  getTimeoutCallback = (end) => {
    return () => {
      const current = new Date().getTime();
      if (this.isPlaying() && current >= end) {
        this.setState(
          (prevState) => {
            return {
              absoluteCurrentIndex: (prevState.absoluteCurrentIndex + 1) % this.getMaxFrames(),
              timeout: null,
            };
          },
          () => {
            this.updateCanvas();
            this.requestTimeout();
          }
        );
      } else if (this.isPlaying()) {
        // loop
        this.createTimeout(end);
      } else {
        this.cancelTimeout();
      }
    };
  };

  updateCanvas = () => {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');
    this.clearCanvas();
    this.drawBackground();
    this.getCurrentFrames().forEach((frame, index) => {
      const curImage = this.props.images[index];
      // Only spend resources to draw a real image
      if (!frame.isEmpty) {
        ctx.drawImage(
          frame.image,
          curImage.x + frame.x,
          curImage.y + frame.y,
          frame.width,
          frame.height
        );
      }
    });
    if (this.state.startingSaveFrame !== null) {
      this.handleSave();
    }
  };

  clearCanvas = () => {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');
    // clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  drawBackground = () => {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = this.props.backgroundColor;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  handleSave = () => {
    this.addFrameToSave();
    const lastFrame = (this.state.startingSaveFrame - 1).mod(this.getMaxFrames());
    if (this.state.absoluteCurrentIndex === lastFrame) {
      // full loop completed
      this.state.gifBuilder.on('finished', (blob) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = `${
          this.state.saveImageName !== '' ? this.state.saveImageName : 'myPeamoji'
        }.gif`;
        element.click();
      });
      this.state.gifBuilder.render(); // Triggers the finshed event when done rendering
      this.setState({ startingSaveFrame: null, gifBuilder: null });
      this.handleSaveDialogClose();
      return;
    }
  };

  addFrameToSave = () => {
    this.state.gifBuilder.addFrame(this.refs.canvas, {
      delay: this.getCurrentMaxDelay(),
      copy: true,
    });
  };

  handleMouseMove = (e) => {
    const canvas = this.refs.canvas;
    const rect = canvas.getBoundingClientRect();

    const x = ((e.clientX - rect.left) / (rect.right - rect.left)) * canvas.width;
    const y = ((e.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height;

    const ctx = canvas.getContext('2d');

    const pixel = ctx.getImageData(x, y, 1, 1);
    this.props.onMouseMove(e, pixel.data);
  };

  handleSaveSubmit = () => {
    const canvas = this.refs.canvas;
    const context = canvas.getContext('2d');
    const width = context.canvas.width;
    const height = context.canvas.height;
    const gifBuilder = getGifBuilder(width, height, this.state.transparentColor);
    const firstFrameIndex = 0;
    this.setState(
      {
        // Reset animation
        absoluteCurrentIndex: firstFrameIndex,
        startingSaveFrame: firstFrameIndex,
        gifBuilder,
        isPlaying: true,
        // Clear timeout so we don't save an extra frame
        timeout: null,
      },
      this.state.isPlaying
        ? () => {
            // Make sure we've reset the canvas to the first frame. This will also
            // trigger a save in case this is a 1-frame image
            this.updateCanvas();
          }
        : // If we're coming from a paused state componentDidUpdate will trigger the canvas update
          () => {}
    );
  };

  getCurrentFrames = () => {
    return this.props.images.map((image) => {
      const offset = image.startIndex;
      const relativeIndex = (this.state.absoluteCurrentIndex - offset).mod(image.frames.length);
      return image.frames[relativeIndex];
    });
  };

  getCurrentMaxDelay = () => {
    return this.getCurrentFrames().reduce(
      (max, frame) => (frame.delay > max ? frame.delay : max),
      0
    );
  };

  getLongestImageFrameCount = () => {
    // TODO consider memoizing
    return this.props.images.reduce(
      (max, image) => (image.frames.length > max ? image.frames.length : max),
      0
    );
  };

  getMaxFrames = () => {
    return this.props.images.reduce(
      (acc, image) => leastCommonMultiple(acc, image.frames.length),
      1
    );
  };

  getLongestImageIndex = () => {
    return this.props.images.reduce(
      (indexOfMax, curImage) =>
        curImage.frames.length > this.props.images[indexOfMax].length
          ? curImage.frames.length
          : indexOfMax,
      0
    );
  };

  getLongestImageCurrentFrame = () => {
    return this.getCurrentFrames()[this.getLongestImageIndex()];
  };

  handlePlayClick = () => {
    this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
  };

  isPlaying = () => {
    return this.props.canPlay && this.state.isPlaying;
  };

  getSaveLoadValue = () => {
    const maxFrames = this.getMaxFrames();
    // Only load up to 75 because we still need to let gifbuilder make the gif after this
    const totalLoadForSaveLoop = 75;
    if (this.state.absoluteCurrentIndex === this.state.startingSaveFrame) {
      return 0;
    }
    if (this.state.startingSaveFrame !== maxFrames + 1) {
      if (this.state.absoluteCurrentIndex < this.state.startingSaveFrame) {
        return (
          ((maxFrames - this.state.startingSaveFrame + this.state.absoluteCurrentIndex) /
            maxFrames) *
          totalLoadForSaveLoop
        );
      }
      if (this.state.absoluteCurrentIndex > this.state.startingSaveFrame) {
        return (
          ((this.state.absoluteCurrentIndex - this.state.startingSaveFrame) / maxFrames) *
          totalLoadForSaveLoop
        );
      }
    } else {
      return (this.state.absoluteCurrentIndex / maxFrames) * totalLoadForSaveLoop;
    }
  };

  handleDragEnd = (overlayX, overlayY, name) => {
    this.props.onImageChangeStart();
    const index = this.props.images.findIndex((image) => image.name === name);
    const image = this.props.images[index];
    this.props.onImageChange([
      ...this.props.images.slice(0, index),
      {
        ...image,
        // We're really moving the mix and min y around, so we need to convert that in terms
        // of the images "absolute" position change
        x: image.x + (overlayX - this.getMinX(image)),
        y: image.y + (overlayY - this.getMinY(image)),
      },
      ...this.props.images.slice(index),
    ]);
  };

  getMaxX = (image) => {
    return Math.max(...image.frames.map((frame) => image.x + frame.x));
  };

  getMaxY = (image) => {
    return Math.max(...image.frames.map((frame) => image.y + frame.y));
  };

  getMinX = (image) => {
    return Math.min(...image.frames.map((frame) => image.x + frame.x));
  };

  getMinY = (image) => {
    return Math.min(...image.frames.map((frame) => image.y + frame.y));
  };

  getWidth = (image) => {
    return this.getMaxX(image) + image.frames[0].width - this.getMinX(image);
  };
  getHeight = (image) => {
    return this.getMaxY(image) + image.frames[0].height - this.getMinY(image);
  };

  isSaveDialogOpen = () => this.props.canShowSaveDialog && this.state.shouldShowSaveDialog;

  handleSaveClick = () => this.setState({ shouldShowSaveDialog: true });

  handleSaveDialogClose = () => this.setState({ shouldShowSaveDialog: false });

  handleTransparentColorChange = (transparentColor) => this.setState({ transparentColor });

  handleNameChange = (e) => this.setState({ saveImageName: e.target.value });

  handleNextFrameClick = () =>
    this.setState(
      (prevState) => ({
        absoluteCurrentIndex: (prevState.absoluteCurrentIndex + 1).mod(this.getMaxFrames()),
        isPlaying: false,
      }),
      () => {
        this.updateCanvas();
      }
    );

  handlePreviousFrameClick = () =>
    this.setState(
      (prevState) => ({
        absoluteCurrentIndex: (prevState.absoluteCurrentIndex - 1).mod(this.getMaxFrames()),
        isPlaying: false,
      }),
      () => {
        this.updateCanvas();
      }
    );

  render() {
    return (
      <>
        <canvas
          width={this.props.width}
          height={this.props.height}
          onClick={this.props.onClick}
          onMouseMove={this.handleMouseMove}
          style={{
            ...styles.canvas,
            cursor: this.props.showEyeDropperCursor ? 'crosshair' : 'inherit',
          }}
          ref="canvas"
        />
        {this.props.canMoveImages && (
          <div
            style={{
              ...styles.imageOverlayWrapper,
              width: this.props.width,
              height: this.props.height,
              left: `calc(50% + ${-1 * (this.props.width / 2)}px)`,
            }}
          >
            {this.props.images.map((image) => (
              <ImageOverlay
                key={image.name}
                x={this.getMinX(image)}
                y={this.getMinY(image)}
                initialX={this.getMinX(image)}
                initialY={this.getMinY(image)}
                width={this.getWidth(image)}
                height={this.getHeight(image)}
                onDragEnd={(deltaX, deltaY) => this.handleDragEnd(deltaX, deltaY, image.name)}
              />
            ))}
          </div>
        )}
        {this.state.finalGifSrc && <img alt="final gif" src={this.state.finalGifSrc} />}
        {this.props.images.length > 0 && this.props.canShowPlayer && (
          <Player
            images={this.props.images}
            onClickPlay={this.handlePlayClick}
            onClickSave={this.handleSaveClick}
            onFrameChange={this.props.onStartIndexChange}
            totalNumFrames={this.getMaxFrames()}
            absoluteCurrentIndex={this.state.absoluteCurrentIndex}
            isPlaying={this.isPlaying()}
            disableButtons={this.state.startingSaveFrame !== null}
            onUndoClick={this.props.onUndoClick}
            onRedoClick={this.props.onRedoClick}
            canUndo={this.props.canUndo}
            canRedo={this.props.canRedo}
            onClickNext={this.handleNextFrameClick}
            onClickPrevious={this.handlePreviousFrameClick}
            onFrameClick={this.props.onFrameClick}
          />
        )}
        <SaveDialog
          isOpen={this.isSaveDialogOpen()}
          onClickSave={this.handleSaveSubmit}
          onClose={this.handleSaveDialogClose}
          onTransparentColorChange={this.handleTransparentColorChange}
          onNameChange={this.handleNameChange}
          onEyeDropperClick={this.props.onEyeDropperClick}
          currentTransparentColor={this.state.transparentColor}
          isLoading={!!this.state.startingSaveFrame}
        />
      </>
    );
  }
}

AnimatedCanvas.propTypes = {
  images: PropTypes.arrayOf(imageShape).isRequired,
  onClick: PropTypes.func,
  onMouseMove: PropTypes.func,
  onStartIndexChange: PropTypes.func,
  showSaveButton: PropTypes.bool,
  canShowSaveDialog: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  canPlay: PropTypes.bool,
  canMoveImages: PropTypes.bool,
  onImageChange: PropTypes.func,
  onImageChangeStart: PropTypes.func,
  onUndoClick: PropTypes.func,
  onRedoClick: PropTypes.func,
  onEyeDropperClick: PropTypes.func,
  canUndo: PropTypes.bool,
  canRedo: PropTypes.bool,
  selectedPixel: PropTypes.objectOf(PropTypes.number),
  showEyeDropperCursor: PropTypes.bool,
  onFrameClick: PropTypes.func,
  canShowPlayer: PropTypes.bool,
};

AnimatedCanvas.defaultProps = {
  onClick() {},
  onMouseMove() {},
  onStartIndexChange() {},
  onImageChange() {},
  onImageChangeStart() {},
  onEyeDropperClick() {},
  showSaveButton: false,
  canShowSaveDialog: false,
  canPlay: true,
  canMoveImages: true,
  onUndoClick() {},
  onRedoClick() {},
  canUndo: false,
  canRedo: false,
  selectedPixel: new Uint8ClampedArray(),
  showEyeDropperCursor: false,
  onFrameClick() {},
  canShowPlayer: true,
};

export default AnimatedCanvas;
