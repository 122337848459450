import { leastCommonMultiple } from './Math';

// Functions
export function randomTranslation(frames, intensity, delay, numOutputFrames = null) {
  const framesToIterate =
    frames.length === 1 ? Array.from(Array(numOutputFrames)).fill(frames[0]) : frames;
  const promises = framesToIterate.map(
    frame =>
      new Promise(resolve => {
        resolve({
          ...frame,
          x: frame.x + getRandomTranslation(intensity),
          y: frame.y + getRandomTranslation(intensity),
          delay,
        });
      })
  );
  return Promise.all(promises);
}

export function translate(image, startPosition, velocity, acceleration, endPosition, delay) {
  const vars = [
    { start: startPosition.x, end: endPosition.x, v: velocity.x, a: acceleration.x, sign: 1 },
    { start: startPosition.x, end: endPosition.x, v: velocity.x, a: acceleration.x, sign: -1 },
    { start: startPosition.y, end: endPosition.y, v: velocity.y, a: acceleration.y, sign: 1 },
    { start: startPosition.y, end: endPosition.y, v: velocity.y, a: acceleration.y, sign: -1 },
  ];
  let minimumSteps = 0;
  if (acceleration.x !== 0 || acceleration.y !== 0) {
    /*
          −v ± √(v^2 + 2aΔx)
      t = −−−−−−−−−−−−−−−−−−
                  a 
    */
    minimumSteps = vars.reduce((max, { start, end, v, a, sign }) => {
      const t = Math.ceil((-1 * v + sign * Math.sqrt(Math.pow(v, 2) + 2 * a * (end - start))) / a);
      if (!isNaN(t) && t > max) {
        return t;
      }
      return max;
    }, 0);
  } else if (velocity.x !== 0 || velocity.y !== 0) {
    // t = Δx/v
    minimumSteps = vars.reduce((max, { start, end, v, a, sign }) => {
      const t = (end - start) / v;
      return t > max ? t : max;
    }, 0);
  } else {
    // No velocity or acceleration -- we can't move
    throw new InvalidParameterException('Velocity or Acceleration is not set.');
  }

  if (isNaN(minimumSteps) || minimumSteps <= 0) {
    throw new InvalidParameterException(
      "Can't move image to End Position with the given Velocity and Acceleration."
    );
  }

  const numAdditionalFrames = leastCommonMultiple(image.frames.length, minimumSteps);

  let newFrames = [];
  for (let i = 0; i <= numAdditionalFrames; i++) {
    const t = i.mod(minimumSteps + 1);
    newFrames.push({
      ...image.frames[i.mod(image.frames.length)],
      x: startPosition.x + t * velocity.x + 0.5 * Math.pow(t, 2) * acceleration.x,
      y: startPosition.y + t * velocity.y + 0.5 * Math.pow(t, 2) * acceleration.y,
      delay: delay,
    });
  }

  return {
    ...image,
    frames: newFrames,
  };
}

function getRandomTranslation(intensity) {
  return (Math.random() - 0.5) * intensity;
}

// Excpetions
export function InvalidParameterException(message) {
  this.message = message;
  this.name = INVALID_PARAMETER_EXCEPTION_NAME;
}

export const INVALID_PARAMETER_EXCEPTION_NAME = 'InvalidParameterException';
