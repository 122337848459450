import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import { imageShape } from '../shapes/ImageShapes';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = {
  itemDragging: {
    background: 'blanchedalmond',
  },
};

class DroppableImageList extends React.Component {
  state = {
    hoveredItem: null,
  };

  handleDragEnd = result => {
    if (!result.destination) {
      // Dropped somewhere it doesn't belong
      return;
    }
    // images are displayed in reverse order, so we have to reverse the indexes back
    const oldIndex = this.props.imageNames.length - 1 - result.source.index;
    const newIndex = this.props.imageNames.length - 1 - result.destination.index;
    this.props.onDragEnd(this.reorder(this.props.imageNames, oldIndex, newIndex));
  };

  handleMouseEnter = name => {
    this.setState({ hoveredItem: name });
  };

  handleMouseLeave = name => {
    if (this.state.hoveredItem === name) {
      this.setState({ hoveredItem: null });
    }
  };

  handleMouseMove = name => {
    if (this.state.hoveredItem === null) {
      this.setState({ hoveredItem: name });
    }
  };

  reorder = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId="layers" direction="vertical">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <List dense disablePadding>
                {this.props.imageNames
                  .slice()
                  .reverse()
                  .map((name, index) => (
                    <React.Fragment key={name + index}>
                      <Draggable draggableId={name} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseEnter={() => this.handleMouseEnter(name)}
                            onMouseMove={() => this.handleMouseMove(name)}
                            onMouseLeave={() => this.handleMouseLeave(name)}
                          >
                            <ListItem
                              divider={index !== this.props.imageNames.length - 1}
                              selected={snapshot.isDragging || this.state.hoveredItem === name}
                              style={snapshot.isDragging ? styles.itemDragging : {}}
                            >
                              <ListItemIcon>
                                <DragHandle />
                              </ListItemIcon>
                              <ListItemText primary={name} />
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    </React.Fragment>
                  ))}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

DroppableImageList.propTypes = {
  imageNames: PropTypes.arrayOf(imageShape).isRequired,
  onDragEnd: PropTypes.func,
};

DroppableImageList.propTypes = {
  onDragEnd() {},
};

export default DroppableImageList;
