import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@material-ui/core';
import image from '../resources/images/PlaceholderImage.png';

const styles = {
  button: {
    background: 'white',
    padding: 0,
    textTransform: 'none',
    width: '60vw',
  },
  image: {
    height: 'auto',
    width: '100%',
  },
  typographyWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
};

const PlaceholderSource = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="outlined" style={styles.button}>
      <Box display="flex" flexDirection="column">
        <img src={image} style={styles.image} alt="No source" tilte="No source image" />
        <div style={styles.typographyWrapper}>
          <Typography component="h5" variant="h5">
            Wow everyone with a new emoji!
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Click here to add an image and get started.
          </Typography>
        </div>
      </Box>
    </Button>
  );
};

PlaceholderSource.propTypes = {
  onClick: PropTypes.func,
};

export default PlaceholderSource;
