import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
  Box,
  Button,
  Snackbar,
} from '@material-ui/core';
import { imageShape } from '../shapes/ImageShapes';
import AnimatedCanvas from './AnimatedCanvas';
import { resizeFrame } from '../image_utilities/Resize';
import isEqual from 'react-fast-compare';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import './FramesCanvas.css';
import { Delete, IndeterminateCheckBox, AddBox, RestoreFromTrash } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const MAX_DIMENSIONS = {
  WIDTH: 150,
  HEIGHT: 150,
};

const styles = {
  overlayWrapper: {
    position: 'relative',
    background: 'white',
    opacity: 0.75,
    minHeight: '300px',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};
const useStyles = makeStyles({
  frame: {
    margin: '16px',
    position: 'relative',
    cursor: 'grab',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'transform .3s',
    },
  },
  framesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    margin: 'auto',
    padding: '24px 12px 64px',
    overflowY: 'auto',
  },
  selectedFrameText: {
    boxShadow: '0px 4px 0px -2px blue',
    transition: 'box-shadow .3s',
  },
  deletedFrameCanvas: {
    filter: 'opacity(50%)',
  },
});

const DraggableFrame = SortableElement(
  ({
    frame,
    frameTitle,
    onFrameClick,
    onFrameDelete,
    onFrameEmpty,
    backgroundColor,
    isSelected,
    isDeleted,
    isEmptied,
  }) => {
    const classes = useStyles();
    const handleDeleteClick = (e) => {
      // Stop click event so we don't select the frame
      e.stopPropagation();
      onFrameDelete();
    };
    const handleEmptyClick = (e) => {
      // Stop click event so we don't select the frame
      e.stopPropagation();
      onFrameEmpty();
    };
    return (
      <div className={classes.frame} onClick={!isDeleted ? onFrameClick : () => {}}>
        <div className={isDeleted ? classes.deletedFrameCanvas : ''}>
          <AnimatedCanvas
            canPlay={false}
            canShowPlayer={false}
            canMoveImages={false}
            images={[!isEmptied ? frame : { ...frame, frames: [{ image: new Image() }] }]}
            width={frame.frames[0].width}
            height={frame.frames[0].height}
            backgroundColor={backgroundColor}
          />
        </div>
        <Box
          display="flex"
          justifyContent="space-between"
          classes={isSelected ? { root: classes.selectedFrameText } : {}}
        >
          <Tooltip title={!isEmptied ? 'Remove Frame Content' : 'Readd Frame Content'}>
            <IconButton
              disabled={isDeleted}
              size="small"
              aria-label="remove content"
              onClick={handleEmptyClick}
            >
              {!isEmptied ? (
                <IndeterminateCheckBox fontSize="small" />
              ) : (
                <AddBox fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={`${isSelected ? 'Deselect' : 'Select'} Frame`}>
            <Button disabled={isDeleted} disableRipple>
              <Typography color="textSecondary" variant="overline">
                {frameTitle}
              </Typography>
            </Button>
          </Tooltip>
          <Tooltip title={!isDeleted ? 'Delete Frame' : 'Readd Frame'}>
            <IconButton size="small" aria-label="delete frame" onClick={handleDeleteClick}>
              {!isDeleted ? <Delete fontSize="small" /> : <RestoreFromTrash fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    );
  }
);

const DraggableFrameContainer = SortableContainer(
  ({
    frames,
    frameOrder,
    deletedFrameIndices,
    emptiedFrameIndices,
    selectedFrameIndices,
    backgroundColor,
    onFrameClick,
    onFrameDelete,
    onFrameEmpty,
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.framesWrapper}>
        {frameOrder.map((frameIndex, index) => {
          const frame = frames[frameIndex];
          return (
            <DraggableFrame
              key={`${frame.name}-${index}`}
              // This index prop is for the draggable
              index={index}
              frame={frame}
              frameTitle={`frame ${frameIndex + 1}`}
              backgroundColor={backgroundColor}
              onFrameClick={() => onFrameClick(frameIndex)}
              onFrameDelete={() => onFrameDelete(frameIndex, index)}
              onFrameEmpty={() => onFrameEmpty(frameIndex)}
              isSelected={selectedFrameIndices.includes(frameIndex)}
              isDeleted={deletedFrameIndices.includes(frameIndex)}
              isEmptied={emptiedFrameIndices.includes(frameIndex)}
            />
          );
        })}
      </div>
    );
  }
);

class FramesCanvas extends React.Component {
  state = {
    isLoading: false,
    frameThumbnails: [],
    draggingIndex: null,
    error: '',
  };

  componentDidMount() {
    if (this.props.previewImage === null) {
      this.makeFrameThumbnails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.previewImage === null &&
      (prevProps.previewImage !== this.props.previewImage ||
        !isEqual(this.props.image, prevProps.image))
    ) {
      this.makeFrameThumbnails();
    }
  }

  getThumbnailDimensions = (frame) => {
    let scale = 1;
    if (frame.width > frame.height && frame.width > MAX_DIMENSIONS.WIDTH) {
      scale = MAX_DIMENSIONS.WIDTH / frame.width;
    } else if (frame.height > MAX_DIMENSIONS.HEIGHT) {
      scale = MAX_DIMENSIONS.HEIGHT / frame.height;
    }
    return { width: frame.width * scale, height: frame.height * scale };
  };

  makeFrameThumbnails = () => {
    this.setState({
      isLoading: true,
    });

    const promises = this.props.image.frames.map((frame) => {
      const dimensions = this.getThumbnailDimensions(frame);
      return resizeFrame(frame, dimensions.width, dimensions.height);
    });

    Promise.all(promises).then((resizedFrames) => {
      this.setState({
        frameThumbnails: resizedFrames.map((frame) => ({
          ...this.props.image,
          frames: [frame],
        })),
        isLoading: false,
      });
    });
  };

  getWidth = () =>
    this.state.frameThumbnails.reduce(
      (acc, thumbnail) => (thumbnail.frames[0].width > acc ? thumbnail.frames[0].width : acc),
      0
    );

  getHeight = () =>
    this.state.frameThumbnails.reduce(
      (acc, thumbnail) => (thumbnail.frames[0].height > acc ? thumbnail.frames[0].height : acc),
      0
    );

  handleSortStart = ({ index }) => {
    this.setState({ draggingIndex: index });
    // This cursor change sucks but it seems like there's no other way with the sortable hoc library
    document.body.style.cursor = 'grabbing';
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    // This cursor change sucks but it seems like there's no other way with the sortable hoc library
    document.body.style.cursor = 'default';

    if (oldIndex === newIndex) {
      return;
    }

    this.setState((prevState) => ({
      draggingIndex: null,
    }));

    this.props.onOrderChange(this.reorder(this.getFrameOrder(), oldIndex, newIndex));
  };

  getFrameOrder = () =>
    this.props.frameOrder.length > 0
      ? this.props.frameOrder
      : Array.from(Array(this.state.frameThumbnails.length).keys());

  reorder = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
    return result;
  };

  handleFrameDelete = (index) => {
    if (
      this.props.framesMarkedForDeletion.includes(index) ||
      this.props.framesMarkedForDeletion.length + 1 < this.props.image.frames.length
    ) {
      this.props.onFrameDelete(index);
      return;
    }

    this.setState({ error: 'Cannot delete all frames. To delete an image, use the Images tool.' });
  };

  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    return (
      <div style={this.state.isLoading ? styles.overlayWrapper : {}}>
        {this.props.previewImage !== null ? (
          <AnimatedCanvas
            canPlay
            canShowPlayer={false}
            canMoveImages={false}
            images={[this.props.previewImage]}
            width={this.props.previewImage.frames[0].width}
            height={this.props.previewImage.frames[0].height}
            backgroundColor={this.props.backgroundColor}
          />
        ) : (
          <DraggableFrameContainer
            frames={this.state.frameThumbnails}
            frameOrder={this.getFrameOrder()}
            deletedFrameIndices={this.props.framesMarkedForDeletion}
            emptiedFrameIndices={this.props.framesMarkedForEmptying}
            selectedFrameIndices={this.props.selectedFrameIndices}
            backgroundColor={this.props.backgroundColor}
            onSortStart={this.handleSortStart}
            onSortEnd={this.handleSortEnd}
            axis="xy"
            lockToContainerEdges
            // Can't think of a good way to use Material UI JSS here
            helperClass="draggingFrame"
            onFrameClick={this.props.onFrameClick}
            onFrameDelete={this.handleFrameDelete}
            onFrameEmpty={this.props.onFrameEmpty}
            // Only register as a drag after moving 5px with mouse down. Allows us to handle click
            distance={5}
          />
        )}
        {this.state.isLoading && (
          <CircularProgress disableShrink size={100} style={styles.progress} />
        )}
        {this.state.error !== '' && (
          <Snackbar open onClose={this.handleErrorClose}>
            <Alert onClose={this.handleErrorClose} variant="filled" severity="error">
              {this.state.error}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

FramesCanvas.propTypes = {
  mainCanvasWidth: PropTypes.number.isRequired,
  mainCanvasHeight: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  image: imageShape.isRequired,
  selectedFrameIndices: PropTypes.arrayOf(PropTypes.number),
  frameOrder: PropTypes.arrayOf(PropTypes.number),
  onFrameClick: PropTypes.func,
  onFrameDelete: PropTypes.func,
  onFrameEmpty: PropTypes.func,
  onOrderChange: PropTypes.func,
  framesMarkedForDeletion: PropTypes.arrayOf(PropTypes.number),
  framesMarkedForEmptying: PropTypes.arrayOf(PropTypes.number),
  previewImage: imageShape,
};
FramesCanvas.defaultProps = {
  frameOrder: [],
  selectedFrameIndices: [],
  onFrameClick() {},
  onFrameDelete() {},
  onFrameEmpty() {},
  onOrderChange() {},
  framesMarkedForDeletion: [],
  framesMarkedForEmptying: [],
  previewImage: null,
};

export default FramesCanvas;
