import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  typographyRoot: {
    position: 'absolute',
  },
});

const TextOverlay = ({ children, text, textProps, containerProps }) => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      {children}
      <Typography classes={{ root: classes.typographyRoot }} {...textProps}>
        {text}
      </Typography>
    </Box>
  );
};

TextOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  textProps: PropTypes.object,
};

TextOverlay.defaultProps = {
  textProps: {},
  containerProps: {},
};

export default TextOverlay;
