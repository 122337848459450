import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Emoji = ({ symbol, label }) => {
  return (
    <span className="emoji" role="img" aria-label={label} aria-hidden={false}>
      <Typography variant="h6">{symbol}</Typography>
    </span>
  );
};

Emoji.propTypes = {
  symbol: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Emoji;
