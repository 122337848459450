import React from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Box } from '@material-ui/core';
import { Colorize } from '@material-ui/icons';
import { formatColor, isValidColor } from '../../image_utilities/Recolor';

const ColorTextField = ({
  label,
  value,
  onColorChange,
  showEyeDropper,
  eyeDropperIsEnabled,
  onEyeDropperClick,
  disabled,
  allowEmpty,
}) => {
  const colorIsEmpty = color => color === '#' || color === '' || color === undefined;
  const isValid = color => (allowEmpty && colorIsEmpty(color)) || isValidColor(color);
  return (
    <Box display="flex" alignItems="flex-end">
      <TextField
        label={label}
        onChange={e => {
          const color = formatColor(e.target.value);
          onColorChange(color, isValid(color));
        }}
        value={value}
        disabled={disabled}
        error={!isValid(value)}
        InputProps={
          showEyeDropper
            ? {
                endAdornment: (
                  <IconButton size="small" onClick={onEyeDropperClick}>
                    <Colorize color={eyeDropperIsEnabled ? 'primary' : 'action'} />
                  </IconButton>
                ),
              }
            : {}
        }
      />
      {!colorIsEmpty(value) && isValid(value) ? (
        <div
          style={{
            borderRadius: '50%',
            height: '15px',
            width: '15px',
            border: '1px solid black',
            backgroundColor: value,
            margin: '3px',
          }}
        />
      ) : (
        <div
          style={{
            borderRadius: '50%',
            height: '15px',
            width: '15px',
            border: '1px solid ' + (isValid(value) ? 'black' : 'red'),
            backgroundColor: 'white',
            margin: '3px',
            fontSize: '10px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: isValid(value) ? 'black' : 'red',
          }}
        >
          <span>X</span>
        </div>
      )}
    </Box>
  );
};

ColorTextField.propTypes = {
  onColorChange: PropTypes.func,
  onEyeDropperClick: PropTypes.func,
  showEyeDropper: PropTypes.bool,
  eyeDropperIsEnabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};
ColorTextField.defaultProps = {
  onColorChange() {},
  onEyeDropperClick() {},
  showEyeDropper: false,
  eyeDropperIsEnabled: false,
  value: '',
  disabled: false,
  allowEmpty: false,
};

export default ColorTextField;
